import React from 'react'
import './about.css'
import whyKsv from './AbtImg/Why KSVSoftTech.jpg'

const IntroPart = () => {
  return (

    <section className="py-3 py-md-5">
      <div className="container">
        <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
          <div className="col-12 col-lg-6 col-xl-5">
            <div data-aos="zoom-in-down">
              <img className="img-fluid rounded" loading="lazy" src={whyKsv} alt="About 1" />
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-7">
            <div className="row justify-content-xl-center">

              <div className="col-12 col-xl-11">
                <div data-aos="fade-left">

                  <h1 className="mb-3 font-48 fw-600">Why KSVSoftTech
                   {/* <span className='higlight'>  KSVSoftTech</span> */}
                   </h1>

                  <p className="mb-5 my-font txt-justify">
                    Founded in 2021, KSVSoftTech stands as a premier IT consulting and software development firm. Our track record boasts successful project deliveries, ensuring smooth execution and global service provision. Specializing in brand craftsmanship and product excellence, KSVSoftTech excels in delivering exceptional results.
                    <br></br>
                    <br></br>
                    Our company was founded with the vision of providing innovative and new technology solutions to businesses across various industries. Over the past few years, we have grown into a team of highly skilled professionals with expertise in diverse domains, including web and mobile app development, cloud computing, artificial intelligence, and data analytics.
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default IntroPart