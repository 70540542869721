import React from 'react'
import './techstack.css'
import technology from '../Image/technology-.png'

const TrustedBusiness = () => {
  return (
    <section className="trustedBusiness tech-container"  >
      <div className="wrapper-1290">

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 d-flex flex-column align-items-center my-font">
            <h1 className="fw-600 font-48 mb-3">
                Top technologies 
                {/* <span className='higlight'>  Technologies</span>  */}
                {/* Elevate Your Online Presence with Our Multi-Tech Expertise */}
              </h1>
              <div className='d-flex justify-content-md-center'>
                <p className="fs-16 text-md-center  line-height-24 max-width-800 fw-400 color-mid-grey my-font">
                  Dedicated to pioneering the latest web technologies, serving diverse businesses worldwide
                </p>
              </div>
            </div>
            <div >
              <div className='col-lg-12 text-center mt-6'>
                <img src={technology} className='tech img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TrustedBusiness;

