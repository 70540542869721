import React from 'react'
import FAQsectionFor from './FAQsectionFor'
import { OfferSection } from '../OfferSection'
import IntroPart from './IntroPart'
import Clients from '../Clients'


const About = () => {
  return (
    <div className='h-100'>

      <IntroPart />
       <OfferSection />
       <Clients />
    </div>
  )
}

export default About