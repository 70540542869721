import React from 'react'
import './solutionStyle.css'
import whatIsErp from './solutionImg/What is ERP software.jpg'
import accessbility from './solutionImg/Access real-time visibility.jpg'


import plannning from './solutionImg/Planning and scheduling.png'
import accounting from './solutionImg/finance and accounting.png'
import chain from './solutionImg/Supply chain management.png'
import cusM from './solutionImg/Customer and order management.png'
import prjM from './solutionImg/Project Management.png'

import ContactUs from '../contactUs/ContactUs'
import FAQ from '../FAQ'
import BottomFooterLine from '../BottomFooterLine'

const erpFeature = [
    { feature: "Finance and accounting", img: accounting ,subPoints:" Streamline financial operations and reporting."},
    { feature: "Supply chain management", img: chain ,subPoints:"Optimize inventory and supplier relationships."},
    { feature: "Planning and scheduling ", img: plannning ,subPoints:"Efficient resource allocation and task planning."},
    { feature: "Project management", img: prjM ,subPoints:"Track progress and manage project resources."},
    { feature: "Customer and order management", img: cusM ,subPoints:"Improve customer relationships and order processing."},

]

const ErpOrders = () => {
    return (
        <>
            <section className='manufacturing-first'>
                <div className='container '>
                    <div className='row  d-flex align-items-center' >
                        <div className='col-md-12 d-flex justify-content-center'>
                            <div className='max-width-900 text-md-center'>
                            <h1 className='home-5-title font-52 mt-md-0 mt-4'>
                            Orders & procurement with ERP</h1>
                            <p className='fs-14 mt-md-3 mt-1 txt-justify'>
                                Enterprise resource planning (ERP) systems help organisations manage their businesses with efficiency and flexibility—bringing together finance, manufacturing, retail, supply chain, warehouse, and stock.
                            </p>
                            {/* <p href="" className="btn mt-md-2 mt-4 rounded fs-16 fw-400 text-white border mt-md-3 py-2 px-3 ctaHeader"><span className="px-1 d-inline-block fontFamily fw-500">Videos </span></p> */}
                            </div>
                        </div>
                        
                    </div>
                </div>

            </section>
            <section className='erp-second'>
                <div className='container-lg  '>
                    <div className='row  d-flex align-items-center ' >
                        {/* <h1 className='home-5-title fw-600 font-52'>
                            What is
                            <span className='higlight'>
                                &nbsp; ERP software &nbsp;
                            </span>
                            and what does it do for business?
                        </h1> */}

                        <div className='col-md-6 order-md-1 order-2 margin-top'>
                            <img src={whatIsErp} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                        <div className='col-md-6 ps-md-5 ps-0 order-md-2 order-1'>
                            <h1 className='home-5-title fw-600 font-48 mt-md-0 mt-4'>
                                Maximize return on  investment 
                                 {/* <span className='higlight'>  Investment </span> */}
                            </h1>
                            <p className='fs-16mt-md-3 mt-1 txt-justify'>
                                Our ERP implementation model is engineered to expedite your journey to value realization and offers the flexibility to seamlessly integrate additional capabilities as your requirements evolve.
                            </p>
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 ps-md-5 ps-0 '>
                            <h1 className='home-5-title fw-600 font-48 mt-md-0 mt-4'>
                                Access real time visibility 
                                {/* <span className='higlight'> time visibility </span> */}
                            </h1>
                            <p className='fs-16mt-md-3 mt-1 txt-justify'>
                                Experience the power of first-class features such as real-time inventory tracking, seamless integration with existing systems, and advanced reporting capabilities.
                            </p>
                        </div>
                        <div className='col-md-6'>
                            <img src={accessbility} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='erp-third'>
                <div className='container '>
                    <h1 className='text-center fw-600 font-48'>ERP Features  
                    {/* <span className='higlight'>  Features</span> */}
                    </h1>
                    <div className='row d-flex justify-content-center mt-md-5 mt-3'>
                        {
                            erpFeature?.map((item, index) => (
                                <div key={`${item?.feature + "-" + index}`} className="col-lg-4 col-md-6 ">
                                    <div className="card-feature " >
                                        <div className="card-body text-center">
                                            <img src={item?.img} className='hw-logo' alt="" />
                                            <h6 className="card-subtitle mt-md-3 mb-md-1 ">{item?.feature}</h6>
                                            <p className="card-text fs-12 text-muted">{item?.subPoints}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            {/* <ContactUs /> */}
            <FAQ />
            <BottomFooterLine />
        </>
    )
}

export default ErpOrders   