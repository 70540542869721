import React from 'react'


import client1 from "../Image/01.png"
import client2 from "../Image/02.png"
import client3 from "../Image/03.png"
import client4 from "../Image/04.png"
import client5 from "../Image/05.png"
import client6 from "../Image/06.png"
import client7 from "../Image/07.png"
import client8 from "../Image/08.png"
import client9 from "../Image/09.png"
import client10 from "../Image/10.png"
import client11 from "../Image/11.png"


const Clients = () => {
    return (
        <section className="featuredOn top-border">
            <div className="clients">
                <div className="wrapper">

                    <div>
                        <h1 className='text-center fw-600 font-48 my-font mb-3'>
                            Our clients
                             {/* <span className='higlight'>Clients</span> */}
                        </h1>
                        <div className='d-flex justify-content-md-center'>
                            <p className='text-md-center fs-16 my-font pb-5 max-width-700 txt-justify'>Join the ranks of our esteemed clients, where success knows no bounds.  Experience empowerment and growth with us.</p>
                        </div>
                    </div>
                    <div className="container-lg ps-md-0 ps-0" style={{ width: "max-content" }}>
                        <div className=" overflow-hidden  d-flex ">
                            <div className="scroll">
                                <img src={client1} className='client-img' alt="" srcSet="" />
                                <img src={client2} className='client-img' alt="" srcSet="" />
                                <img src={client3} className='client-img' alt="" srcSet="" />
                                <img src={client4} className='client-img' alt="" srcSet="" />
                                <img src={client5} className='client-img' alt="" srcSet="" />
                                <img src={client6} className='client-img' alt="" srcSet="" />
                                <img src={client7} className='client-img' alt="" srcSet="" />
                                <img src={client8} className='client-img' alt="" srcSet="" />
                                <img src={client9} className='client-img' alt="" srcSet="" />
                                <img src={client10} className='client-img' alt="" srcSet="" />
                                <img src={client11} className='client-img' alt="" srcSet="" />

                            </div>
                            <div className="scroll">
                                <img src={client1} className='client-img' alt="" srcSet="" />
                                <img src={client2} className='client-img' alt="" srcSet="" />
                                <img src={client3} className='client-img' alt="" srcSet="" />
                                <img src={client4} className='client-img' alt="" srcSet="" />
                                <img src={client5} className='client-img' alt="" srcSet="" />
                                <img src={client6} className='client-img' alt="" srcSet="" />
                                <img src={client7} className='client-img' alt="" srcSet="" />
                                <img src={client8} className='client-img' alt="" srcSet="" />
                                <img src={client9} className='client-img' alt="" srcSet="" />
                                <img src={client10} className='client-img' alt="" srcSet="" />
                                <img src={client11} className='client-img' alt="" srcSet="" />
                            </div>
                            <div className="scroll">
                                <img src={client1} className='client-img' alt="" srcSet="" />
                                <img src={client2} className='client-img' alt="" srcSet="" />
                                <img src={client3} className='client-img' alt="" srcSet="" />
                                <img src={client4} className='client-img' alt="" srcSet="" />
                                <img src={client5} className='client-img' alt="" srcSet="" />
                                <img src={client6} className='client-img' alt="" srcSet="" />
                                <img src={client7} className='client-img' alt="" srcSet="" />
                                <img src={client8} className='client-img' alt="" srcSet="" />
                                <img src={client9} className='client-img' alt="" srcSet="" />
                                <img src={client10} className='client-img' alt="" srcSet="" />
                                <img src={client11} className='client-img' alt="" srcSet="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Clients