import React, { useState } from 'react'

const FAQ = () => {
    const [showAccordianOne, setShowAccordianOne] = useState(false)
    const [showAccordianTwo, setShowAccordianTwo] = useState(false)
    const [showAccordianThree, setShowAccordianThree] = useState(false)
    const [showAccordianFour, setShowAccordianFour] = useState(false)
    return (
        <>
            <section className="mainFAQ my-font">
                <div className="wrapper-1290 pd-top">
                    <h1 className="mainFAQ-title-1 font-48 text-center">Frequently asked question
                    {/* <span className='higlight'>Question's</span> */}
                    </h1>
                    <div className="d-flex justify-content-between viewPoint">
                        <div className="col-12 mainFAQ-left">
                            <div className="mainFAQ-box">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item border-0">
                                        <h3 className="accordion-header" id="headingOne">
                                            <button
                                                className={`accordion-button fs-18 fw-600 line-height-24 color-primary-black bg-white shadow-none p-0  ${showAccordianOne && "collapsed"}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                                onClick={() => setShowAccordianOne(!showAccordianOne)}
                                            >

                                                What should I look for in a software application development company?

                                            </button>
                                        </h3>
                                        <div
                                            id="collapseOne"
                                            className={`accordion-collapse collapse ${showAccordianOne && "show"} `}
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body p-0">
                                                <p className="fs-16 fw-400 line-height-24 color-primary-black pt-3 pb-1 my-font">
                                                    Once you know what the services offered by a software development company are, now you need to understand how to find the right company for your project requirements. Here is a checklist of things you should consider before hiring the software development services company:
                                                </p>
                                                <ul>
                                                    <li className="fs-16 fw-400 line-height-24 color-primary-black pb-1">
                                                        Does the company have experience related to your project? Check their work and ask for similar projects they have worked on in the past. Read the company’s reviews on google or social media.
                                                    </li>
                                                    <li className="fs-16 fw-400 line-height-24 color-primary-black pb-1">
                                                        Does the software development company have expertise in the technologies you require? For example, if you need to develop a java product, check if they provide java development services.

                                                    </li>
                                                    <li className="fs-16 fw-400 line-height-24 color-primary-black pb-1">
                                                        Consider the company’s team size. If you pick a company that is too small, you have a risk that you may not get better support. If the company is too large, there’s a risk that you might not get enough attention. Therefore, be sure to work with a company that has a team size based on your project requirements.

                                                    </li>
                                                    <li className="fs-16 fw-400 line-height-24 color-primary-black pb-1">
                                                        Check if the company has received any recognition or awards. You need to ensure that the company has the right skills and knows how to deliver the software successfully
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border-0">
                                        <h3 className="accordion-header" id="headingTwo">
                                            <button
                                                className={`accordion-button fs-18 fw-600 line-height-24 color-primary-black bg-white shadow-none p-0  ${showAccordianTwo && "collapsed"}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                                onClick={() => setShowAccordianTwo(!showAccordianTwo)}
                                            >
                                                What do we do as a software development company?
                                            </button>
                                        </h3>
                                        <div
                                            id="collapseTwo"
                                            className={`accordion-collapse collapse ${showAccordianTwo && "show"} `}
                                            aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body p-0">
                                                <p className="fs-16 fw-400 line-height-24 color-primary-black pt-3 pb-3">
                                                    As a software development company, our aim is to build software or software applications that include identifying a need, designing the software, coding it, and testing for the issue
                                                </p>
                                                <strong> Following are some of the software development services offered by our company:</strong>
                                                <p className="fs-16 fw-400 line-height-24 color-primary-black pb-3">
                                                    <strong className="fw-600">
                                                        We develop digital solutions to problems:
                                                    </strong>
                                                    Though processes and techniques might not be similar, we aim to create solutions that solve the problems of a consumer. These solutions are full-scale software that is designed and developed to meet the needs of businesses.
                                                </p>
                                                <p className="fs-16 fw-400 line-height-24 color-primary-black pb-3">
                                                    <strong className="fw-600">
                                                        We produce useful programs: 
                                                    </strong>
                                                    We also provide consultation and research ways to elevate our client’s businesses with our technical expertise in different technologies.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mainFAQ-right ">
                            <div className="mainFAQ-box">
                                <div className="accordion" id="accordionExample">

                                    <div className="accordion-item border-0">
                                        <h3 className="accordion-header" id="headingThree">
                                            <button
                                                className={`accordion-button fs-18 fw-600 line-height-24 color-primary-black bg-white shadow-none p-0  ${showAccordianThree && "collapsed"}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                                onClick={() => setShowAccordianThree(!showAccordianThree)}
                                            >
                                                How much does it cost to build software?
                                            </button>
                                        </h3>
                                        <div
                                            id="collapseThree"
                                            className={`accordion-collapse collapse ${showAccordianThree && "show"} `}
                                            aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body p-0">
                                                <p className="fs-16 fw-400 line-height-24 color-primary-black pt-3 pb-4">
                                                    The cost of a software project depends on several factors including the project scope, time spent, and technological issues. Each project is unique, so providing an accurate estimate is difficult, but the goal is to provide the best quality within the client’s budget. Pricing depends on software features, platform support, and type of development. Other factors such as ui design, integrations, and performance requirements also play a significant role.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border-0">
                                        <h3 className="accordion-header" id="headingFour">
                                            <button
                                                className={`accordion-button fs-18 fw-600 line-height-24 color-primary-black bg-white shadow-none p-0  ${showAccordianFour && "collapsed"}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                                onClick={() => setShowAccordianFour(!showAccordianFour)}
                                            >
                                                What are the software development services provided by our software development company?
                                            </button>
                                        </h3>
                                        <div
                                            id="collapseFour"
                                            className={`accordion-collapse collapse ${showAccordianFour && "show"} `}
                                            aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body p-0">
                                                <ul>
                                                    <li className="fs-16 fw-400 line-height-24 color-primary-black pt-3 pb-3">
                                                        <strong>
                                                            Software Consulting :
                                                        </strong> Our Software Consulting Team helps clients with critical know-how to provide insights on how a specific software application would benefit their business. Our IT consultants run the feasibility check for a clients and identify if their requirements are feasible and can be implemented in software that they are looking to build. Based on the client's requirements, we create a detailed plan and product roadmap for the development of software.
                                                    </li>
                                                    <li className="pb-3">
                                                        <strong>
                                                            Visual and Technical Design :
                                                        </strong> We also design the user interfaces of an application that meets the client’s business requirements. We create a clear and precise design that provides a better user experience. As a part of the design service, our software development company creates frames, prototypes, and UI/UX designs.

                                                    </li>
                                                    <li className="pb-3">
                                                        <strong>
                                                            Web Development :
                                                        </strong> Web Development means building software applications that run in web environments. It can be a website or web application that includes multiple services in a single sophisticated architecture.

                                                    </li>
                                                    <li className="pb-3">
                                                        <strong>
                                                            Mobile App Development :
                                                        </strong> Mobile App Development has gained a lot of traction from the time smartphones were discovered. Mobile App development is categorized into two operating systems: iOS and Android. Our developers can also produce cross-platform applications that make use of web technologies, for example, Progressive Web Apps.

                                                    </li>
                                                    <li className="pb-3">
                                                        <strong>
                                                            Data Science and Artificial Intelligence :
                                                        </strong> From small to medium and large size organizations, every organization generates plenty of data. Therefore, our software development company can help them unlock the value of their unstructured data by building analytics tools. They use technologies, including machine learning, deep learning, and more.

                                                    </li>
                                                </ul>
                                                <p className="fs-16 fw-400 line-height-24 color-primary-black pt-3 pb-4">

                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQ