import React from 'react'
import emp1 from '../../Image/emp1.jpg'
import emp2 from '../../Image/emp2.jpg'
import emp3 from '../../Image/emp3.jpg'
import emp4 from '../../Image/emp4.jpg'

const OurTeam = () => {
    return (
        <section className="mainFAQ  pd-top ">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                        <h2 className="mb-3 my-font h-text text-lg-center">Our Team</h2>
                        <div className='d-flex justify-content-md-center text-lg-center'>
                            <p className="my-font fw-500 mb-5  lead txt-justify">We are a group of innovative, experienced, and proficient teams. You will love to collaborate with us.</p>
                            {/* <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper-1290">
                <div className="d-flex viewPoint">
                    <div className="col-12 mainFAQ-right team-left pd-60">
                        <div className="FAQ-head">
                           <p className='my-font fw-600 fs-20'>Meet the Dream Team</p> 
                           <p className='my-font fs-14'>Get acquainted with the powerhouse behind our success.</p>
                        </div>
                    </div>
                    <div className="col-12 mainFAQ-left team-right">

                        <div className="mainFAQ-box p-md-0">

                            <section className=" py-3 py-md-5 py-xl-8">

                                <div className="container overflow-hidden">
                                    <div className="row gy-4 gy-lg-0 gx-xxl-5">
                                        <div className="col-12 col-md-4 mb-md-4">
                                            <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                                                <div className="card-body p-0">
                                                    <figure className="m-0 p-0">
                                                        <div className='circle'>
                                                        <img className="img-fluid team-image" loading="lazy" src={emp1} alt="" />
                                                        </div>
                                                        <figcaption className="m-0 p-4">
                                                            <h4 className="mb-1">Flora Nyra</h4>
                                                            <p className="text-secondary mb-0">Product Manager</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 mb-md-4">
                                            <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                                                <div className="card-body p-0">
                                                    <figure className="m-0 p-0">
                                                    <div className='circle'>
                                                        <img className="img-fluid team-image" loading="lazy" src={emp2} alt />
                                                         </div>
                                                        <figcaption className="m-0 p-4">
                                                            <h4 className="mb-1">Evander Mac</h4>
                                                            <p className="text-secondary mb-0">Art Director</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 mb-md-4">
                                            <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                                                <div className="card-body p-0">
                                                    <figure className="m-0 p-0">
                                                    <div className='circle'>                                                     
                                                        <img className="img-fluid team-image" loading="lazy" src={emp3} alt />
                                                    </div>
                                                        <figcaption className="m-0 p-4">
                                                            <h4 className="mb-1">Taytum Elia</h4>
                                                            <p className="text-secondary mb-0">Investment Planner</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 mb-md-4">
                                            <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                                                <div className="card-body p-0">
                                                    <figure className="m-0 p-0">
                                                    <div className='circle'>                                                     
                                                        <img className="img-fluid team-image" loading="lazy" src={emp4} alt />
                                                     </div>
                                                        <figcaption className="m-0 p-4">
                                                            <h4 className="mb-1">Wylder Elio</h4>
                                                            <p className="text-secondary mb-0">Financial Analyst</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 mb-md-4">
                                            <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
                                                <div className="card-body p-0">
                                                    <figure className="m-0 p-0">
                                                        <div className='circle'>
                                                        <img className="img-fluid team-image" loading="lazy" src={emp1} alt="" />
                                                        </div>
                                                        <figcaption className="m-0 p-4">
                                                            <h4 className="mb-1">Flora Nyra</h4>
                                                            <p className="text-secondary mb-0">Product Manager</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurTeam