import React from "react";
import "./componentStyle.css";
import leftImage from "../Image/home.png";
import review from "../Image/review.png";

const HeroSEction = () => {
  return (
    <>
      <section className="hero-sec mb-curve">
        {/* <img src={gif} alt="" /> */}
        <div className="wrapper-1290">
          <div className="row d-flex align-items-center ">
            <div className="col-12 md-1 col-lg-8">
              <h1 className="font-52 text-white fw-600 animation-txt"></h1>
              <p className="fs-14 m-view-font max-width-651 text-white fontFamily text-wrap mt-2 my-font">
                Developing innovative solutions using java, react, ml, ai, and
                other advanced technologies to drive innovation for a better
                world.
              </p>
              {<img src={review} className="google-review" alt="" />}
            </div>
            <div className="col-12 col-lg-4 text-center d-block d-sm-none">
              <figure className="hero-banner-figure">
                {
                  <img
                    src={leftImage}
                    className="img-fluid hero-left-image global-tech"
                    alt=""
                  />
                }
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSEction;
