import React, { useEffect, useRef, useState } from 'react'
import logo from '../Image/logo.png'
import './componentStyle.css'
import { Link, useNavigate } from 'react-router-dom'
import * as Scroll from 'react-scroll';
import { Link as ScrollLink } from 'react-scroll'
import downArrow from '../Image/arrow.png'

import ai from '../Image/ai.png'
import uiux from '../Image/ui_ux.png'
import appD from '../Image/app.png'
import digital from '../Image/digital.png'
import mobility from '../Image/mobility.png'
import chatBot from '../Image/chatbot.png'
import hwForces from '../Image/hw.png'
import erp from '../Image/erp.png'
import face from '../Image/facial.png'
import crm from '../Image/crm.png'
import info from '../Image/info.png'
import team from '../Image/team.png'
import blog from '../Image/blog.png'
import career from '../Image/career.png'


const Header = () => {
  const [mobileNav, setMobileNav] = useState(false)
  const ref = useRef(null)
  const [width, setWidth] = useState(window.innerWidth)
  const [platformNavList, setPlatformNavList] = useState(false)
  const [solutionList, setSolutionList] = useState(false);
  const [whyksvList, setWhyksvList] = useState(false);
  const scroller = Scroll.scroller;
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const handleWidth = (navListName) => {
    let widthR = ref.current.offsetWidth;
    setWidth(widthR)
    if (width < 770 && navListName === 'platform') {
      setPlatformNavList(!platformNavList)
      setWhyksvList(false)
      setSolutionList(false)
    } else if (width < 770 && navListName === 'solution') {
      setSolutionList(!solutionList)
      setWhyksvList(false)
      setPlatformNavList(false)
    } else if (width < 770 && navListName === 'whyksv') {
      setWhyksvList(!whyksvList)
      setPlatformNavList(false)
      setSolutionList(false)
    }
    return;
  }

  const navClosed = () => {
    setMobileNav(false)
  }
  const goToHomeAndScroll = async (scrollName) => {

    navigate('/platform')
    setMobileNav(false)
    console.log(scroller, scrollName, "ooooooooooooo");
    setTimeout(async () => {
      await scroller.scrollTo(scrollName, {
        duration: 500,
        delay: 50,
        smooth: true,
        offset: 50
      });
    }, 1000)
  };

  return (
    <>
      <div ref={ref}>
        <nav className="navbar navbar-expand-md  " style={{ paddingTop: 0, paddingBottom: 0 }}>
          <div className="container-fluid">
            <div className='mobile-nav'>
              <div>
                <a className="navbar-brand ps-lg-4 ps-0">
                  <Link to={'/'}>
                    <img
                      src={logo}
                      width={200}
                      alt="Logo"
                      style={{ cursor: 'pointer' }}
                      className='ksv-logo'
                    />
                  </Link>
                </a>
              </div>
              <button
                className="navbar-toggler px-1 me-3"
                type="button"
                aria-label="menu toggle"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
                onClick={() => {
                  setMobileNav(!mobileNav)
                  console.log(mobileNav, "clickedddddddddddddddddd");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={28}
                  height={28}
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M4 14L18 14"
                    stroke="#1D2434"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 7L24 7"
                    stroke="#1D2434"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 21L24 21"
                    stroke="#1D2434"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className={`collapse navbar-collapse ${mobileNav && "show"} row d-md-flex `} id="mynavbar" >
              <div className='col-lg-10 col-md-9 d-flex justify-content-center p-0  position-relative'>
                <ul className="navbar-nav ps-lg-3 ps-0 align-items-center web-navbar-links-group">

                  <li className="nav-item platform"
                    onClick={() => handleWidth("platform")}
                  >
                    <p
                      className="nav-link text-decoration-none color-primary-black fs-16  fw-600 px-3 d-flex align-items-center"
                      href="../reviews.html"
                    >
                      Platform
                      <img src={downArrow} className={`header-arrow  ${platformNavList ? "arrow-animation" : "reverse-arrow"} `} id='nav-platform' alt="" srcSet="" />
                    </p>

                    <div className={`dropdown-menu  ${width > 766 ? "dropdown-platform" : platformNavList != false ? "dropdown-platform-mobile" : null} `}>
                      <div className='row d-flex w-100 ps-lg-2 ms-lg-1 ' >
                        <div className="navbar-list d-flex  ps-md-0 ps-4 justify-content-md-start col-md-4 col-sm-12  ">
                          <ul>
                            <li className="d-flex " onClick={() => goToHomeAndScroll("appDev")} >
                              <div className="text-decoration-none align-items-center border-0 d-flex my-font" >
                                <img src={appD} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  Application Development
                                  <p className="list-content">Unleash the power of the web.</p>
                                </div>
                              </div>
                            </li>
                            <li className="d-flex " onClick={() => goToHomeAndScroll("ai")} >
                              <div className="text-decoration-none align-items-center border-0 d-flex my-font" >
                                <img src={ai} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  AI/Machine Learning
                                  <p className="list-content">Embrace the future of intelligent technologies</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="navbar-list d-flex ps-md-0 ps-4 justify-content-md-start  col-md-4 col-sm-12  ">
                          <ul>
                            <li className="d-flex " onClick={() => goToHomeAndScroll("Digital")} >
                              <div className="text-decoration-none align-items-center border-0 d-flex my-font" >
                                <img src={digital} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  Digital Marketing
                                  <p className="list-content">Ignite your brand's digital footprint</p>
                                </div>
                              </div>
                            </li>
                            <li className="d-flex " onClick={() => goToHomeAndScroll("mobility")} >
                              <div className="text-decoration-none align-items-center border-0 d-flex my-font" >
                                <img src={mobility} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  Mobility Solutions
                                  <p className="list-content"> Unlock the possibilities of mobile innovation</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="navbar-list d-flex ps-md-0 ps-4 justify-content-md-start col-md-4 col-sm-12">
                          <ul>
                            <li className="d-flex " onClick={() => goToHomeAndScroll("webDesign")} >
                              <div className="text-decoration-none align-items-center border-0 d-flex my-font" >
                                <img src={uiux} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  UI/UX WebDesign
                                  <p className="list-content">Experience design that captivates
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </li>

                  <li className="nav-item solution"
                    onClick={() => handleWidth("solution")}
                  >
                    <p
                      className="nav-link text-decoration-none color-primary-black fs-16 my-font fw-600 pe-md-3 px-3d-flex align-items-center"
                    >
                      Solution
                      <img src={downArrow} className={`header-arrow  ${solutionList ? "arrow-animation" : "reverse-arrow"} `} id='nav-solution' alt="" srcSet="" />
                    </p>
                    <div className={`dropdown-menu ${width > 766 ? "dropdown-solution" : solutionList != false ? "dropdown-solution-mobile" : null} `}>

                      <div className='row d-flex justify-content-center justify-content-md-start  w-100 ps-lg-2 ms-lg-1 ' >
                        <div className="navbar-list  d-flex ps-md-0 ps-4 justify-content-md-start  col-md-4  col-sm-12  ">

                          <ul>
                            <li  >
                              <Link to="/hwforces" onClick={() => navClosed()} className="text-decoration-none align-center align-items-center border-0 my-font d-flex" >
                                <img src={hwForces} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  HWForces
                                  <p className="list-content">
                                    Your ultimate HR management solution
                                  </p>
                                </div>
                              </Link>
                            </li>
                            <li >
                              <Link to="/erpInventory" onClick={() => navClosed()} className="text-decoration-none align-center align-items-center border-0 my-font d-flex" >
                                <img src={erp} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  Inventory & Warehouse
                                  <p className="list-content">Master inventory management Inventory & Warehouse solutions</p>
                                </div>
                              </Link>
                            </li>
                            <li >
                              <Link to="/eWallet" onClick={() => navClosed()} className="text-decoration-none align-center align-items-center border-0 my-font d-flex" >
                                <img src={erp} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  E-wallet
                                  <p className="list-content">Secure digital payments at your fingertips.</p>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="navbar-list d-flex ps-md-0 ps-4 justify-content-md-start   col-md-4 col-sm-12  ">
                          <ul>
                            <li >
                              <Link to="/erpAccounting" onClick={() => navClosed()} className="text-decoration-none align-center align-items-center border-0 my-font d-flex" >
                                <img src={erp} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  Accounting & Finance
                                  <p className="list-content">Streamline your finances with ERP Accounting & Finance</p>
                                </div>
                              </Link>
                            </li>
                            <li  >
                              <Link to="/manufacturing" onClick={() => navClosed()} className="text-decoration-none align-center align-items-center border-0 my-font d-flex" >
                                <img src={chatBot} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  Manufacturing & Production
                                  <p className="list-content">Efficient procurement solutions Orders & Procurement</p>
                                </div>
                              </Link>
                            </li>

                          </ul>
                        </div>
                        <div className="navbar-list d-flex  ps-md-0 ps-4 justify-content-md-start col-md-4 col-sm-12 ">
                          <ul>

                            <li >
                              <Link to="/erpOrder" onClick={() => navClosed()} className="text-decoration-none align-center align-items-center border-0 my-font d-flex" >
                                <img src={erp} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  Orders & Procurement
                                  <p className="list-content">Optimize production processes Manufacturing & Production</p>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/crm" onClick={() => navClosed()} className="text-decoration-none align-center align-items-center border-0 my-font d-flex" >
                                <img src={crm} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  CRM & Marketing Automation
                                  <p className="list-content">Maximize customer relationships with CRM & Marketing Automation</p>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item why-ksv" onClick={() => handleWidth("whyksv")}

                  >
                    <p
                      className="nav-link text-decoration-none color-primary-black fs-16 my-font fw-600 px-3d-flex align-items-center"
                    >
                      Why KSVSoftTech ?
                      <img src={downArrow} className={`header-arrow  ${whyksvList ? "arrow-animation" : "reverse-arrow"} `}
                        id='nav-whyksv' alt="" srcSet="" />

                      {/* <span className="pink_new fw-500 fs-12">New</span> */}
                    </p>
                    <div className={`dropdown-menu ${width > 766 ? "dropdown-why-ksv" : whyksvList != false ? "dropdown-why-ksv-mobile" : null} `}>


                      <div className='row d-flex w-100 ps-lg-2 ms-lg-1 ' >
                        <div className="navbar-list d-flex ps-md-0 ps-4 justify-content-md-start  col-md-4 col-sm-12  ">

                          <ul>
                            <li >
                              <Link to="/about" onClick={() => navClosed()} className="text-decoration-none border-0 my-font align-center d-flex align-items-center" >
                                <img src={info} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  About KSVSoftTech
                                  <p className="list-content">Discover KSVSoftTech innovators in technology solutions</p>
                                </div>
                              </Link>
                            </li>

                            {/* <li className="" >
                              <Link to="/team" onClick={() => navClosed()} className="text-decoration-none border-0 d-flex my-font" >
                                <img src={team} alt='img' className='nav-list-logo' />
                                <div>
                                  Our Team
                                  <p className="list-content">Lorem ipsum dolor sit amet.</p>
                                </div>
                              </Link>
                            </li> */}

                          </ul>
                        </div>
                        <div className="navbar-list  d-flex ps-md-0 ps-4 justify-content-md-start   col-md-4 col-sm-12 ">
                          <ul>
                            <li>
                              <Link to="/career" onClick={() => navClosed()} className="text-decoration-none border-0 align-center d-flex my-font align-items-center" >
                                <img src={career} alt='img' className='nav-list-logo' />
                                <div className='hover-nav'>
                                  Career
                                  <p className="list-content">Build your career path with KSVSoftTech.</p>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="navbar-list d-flex justify-content-center justify-content-md-start  col-md-4 col-sm-12 ">
                          <ul>
                            {/* <li >
                              <Link to="/blog" onClick={() => navClosed()} className="text-decoration-none border-0 d-flex my-font" >
                                <img src={blog} alt='img' className='nav-list-logo' />
                                <div>
                                  Blog
                                  <p className="list-content">Lorem ipsum dolor sit amet.</p>
                                </div>
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 d-flex justify-content-center col-md-3 p-sm-0 head-cta-sec ">
                <Link
                  to={'/contactpage'}
                  onClick={() => navClosed()}
                  className="btn  rounded fs-16 fw-400 text-white  py-2 px-3 line-height-26 theme-color ctaHeader"
                >
                  <span className="px-1 d-inline-block fontFamily fw-500">Contact Us</span>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>

    </>
  )
}

export default Header

