import React from 'react'
import uxhorizoltal1 from './ptImages/uxHori1.png'



const Uiux = () => {
    return (
        <section style={{ backgroundColor: "#eaeaea94" }}>

            <div className='container' name="webDesign" id="webDesign"  >
                <div className="row align-items-center gap-md-5  erp-pd">
                    <div className="col-lg-6 col-md-12 order-2 order-lg-1 mt-4 pt-2 mt-sm-0 opt-sm-0">
                        <img src={uxhorizoltal1} className="img-fluid " alt="Image" />

                        {/*end row*/}
                    </div>
                    {/*end col*/}
                    <div className="col-lg-5 col-md-12  order-1 order-lg-2">
                        <div className="section-title ml-lg-5">
                            <h1 className="mb-3 fw-600 font-48"> UI/UX webdesign
                            {/* <span className='higlight'>WebDesign</span> */}
                            </h1>

                            <p className="text-muted mb-0 fs-14">
                                Immerse your users in intuitive and visually stunning interfaces that elevate their journey. Our UI/UX experts meticulously craft human-centric designs, ensuring seamless navigation and unforgettable digital experiences.
                            </p>

                            <h5 className='home-5-title  mt-md-4 mt-4'>Services
                            </h5>

                            <div className="row">
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">User interface (UI) design</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">User experience (UX) design</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex  rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 px-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Wireframing and prototyping</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Visual design</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end col*/}
                </div>
            </div>
        </section>

    )
}

export default Uiux