import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import './career.css'
import { viewJob } from './careerConstantData';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const formInitialValue = {
    name: '',
    email: '',
    number: '',
    jobPosition: '',
    uploadResume: '',
    message: ''
}

const Career = () => {
    const [openApplyModal, setOpenApplyModal] = useState(false)
    const [jobDescription, setJobDescription] = useState()
    const [formObj, setFormObj] = useState(formInitialValue);
    const [missingField, setMissingFiled] = useState('')

    const HandleModal = (jobData) => {
        setOpenApplyModal(true)
        setJobDescription(jobData)
    }
    const closeModal = () => {
        setOpenApplyModal(false);
    };


    const isValidEmail = () => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return validRegex.test(formObj.email);
    }

    const isValidPhoneNumber = () => {
        const phonePattern = /^\d{10}$/;
        return phonePattern.test(formObj.number);
    };

    const InputChange = (e) => {
        const { name, value, type } = e.target;
        if (type === "file") {
            console.log(e.target.files[0], "pppppppppppppppp");
            setFormObj({ ...formObj, [name]: e.target.files[0] })
        }
        else {
            setFormObj({ ...formObj, [name]: value });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validEmail = isValidEmail();
        if (!formObj.name || !formObj.email || !formObj.number || !formObj.jobPosition || !formObj.uploadResume) {
            setMissingFiled('Please fill this fields.')
            return
        }
        if (!validEmail) {
            return
        }
        const formData = new FormData();
        for (let key in formObj) {
            formData.append(key, formObj[key]);
        }
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        let res = await axios.post('', formData, {
            headers: headers
        })
        if (res?.status === 200) {
            setOpenApplyModal(false)
            toast.success('Thank you for your request. Your request sent successfully.')
        } else {
            toast.error('Something went wrong. Please try again later.')
        }
        setFormObj({ ...formInitialValue })
        setMissingFiled('')
    }

    const handleForm = (e) => {
        e.preventDefault();
        toast.error('Something went wrong. Please try again later.')
    }


    return (
        <>
            <section id="sectionJobList" className="section section-job-list gradient-light--lean-left">
                <div className="container">
                    <div className="row row-grid justify-content-center">
                        <div className="col-md-8 col-lg-7 col-xl-6 text-center">
                            <h2 className="section__title fs-40 mb-4">Job openings at <span className='text-white'> KSVSoftTech</span></h2>
                            <p className='fs-14 mt-md-3 mt-1 txt-justify'>KSVSoftTech is an equal opportunity employer focused on consistency in learning, growth, and success. Join Us to achieve your goals and give wings to your dreams.</p>
                        </div>
                        <div className="col-md-10">
                            <div className="job-list__wrapper  mb-6">
                                {
                                    viewJob?.map((item, index) => (
                                        <>
                                            <p className='openings rounded-pill'> Openings</p>
                                            <div className="card job-card p-0 mb-3 border-0 shadow-sm shadow--on-hover">
                                                <div className="card-body p-4">
                                                    <span className="row justify-content-between align-items-center">
                                                        <span className="col-md-5 color--heading">
                                                            {item?.profile}
                                                        </span>
                                                        <span className="col-5 col-md-3 my-3 my-sm-0 color--text">
                                                            <i className="fas fa-clock mr-1" /> Full time
                                                        </span>
                                                        <span className="col-7 text-end  col-md-3  color--text">
                                                            <button onClick={() => HandleModal(item)}
                                                                className="btn btn-outline-dark border border-dark"
                                                            >
                                                               Apply Now
                                                            </button>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div>
                <Modal show={openApplyModal} onHide={closeModal} fullscreen={true}  >
                    <ToastContainer position='top-center' reverseOrder={false} />
                    <Modal.Header closeButton>
                        <Modal.Title>Apply Here</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className='row modal-scroll'>
                            <div className=' col-lg-6  col-md-12 d-flex justify-content-lg-end col-sm-12 px-md-5 px-2'>
                                <div>
                                    <h1>{jobDescription?.profile}</h1>
                                    <p className='openings2 rounded-pill w-25 text-center my-1' > Openings {jobDescription?.openings} </p>
                                    <h5 className='mt-md-4 mt-2 ex-line'>Experience Required - {jobDescription?.experience} </h5>
                                    <p>We are looking for {jobDescription?.profile} with minimum of {jobDescription?.experience}  of experience</p>
                                    <h5 className=' mt-md-4 mt-2'>Skils Required</h5>
                                    <div className='d-flex flex-wrap'>
                                        {
                                            jobDescription?.skills?.map((item, index) => (
                                                <p className='mx-1 rounded-pill border-info border px-3 py-2 text-center my-1'
                                                    key={index + "_SKill"}
                                                > {item}</p>
                                            ))
                                        }

                                    </div>
                                    <div className='job-description'>
                                        <h5 className=' mt-md-4 mt-2'>Job Description</h5>
                                        <ul>
                                            {
                                                jobDescription?.jobDescription?.map((item, index) => (
                                                    <li key={index + "_JobDescription"} >{item}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12  d-flex justify-content-lg-start'>
                                <div className="card  rounded border-0  p-4 " style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;" }}>
                                    <form className='p-md-4 p-2'
                                    //  onSubmit={(e) => handleSubmit(e)} 

                                    >
                                        <div className="row modal-scroll">

                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="name"> Name</label>
                                                <input type="text" name='name' maxLength={15}
                                                    className="form-control"
                                                    onChange={(e) => InputChange(e)} value={formObj.name}
                                                    placeholder="Enter your name" />
                                                {
                                                    formObj?.name?.length == 0 ? " " : formObj?.name?.length == 15 &&
                                                        <p className='text-danger fs-12'> Name reached at max character </p>
                                                }
                                            </div>

                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" name='email' className="form-control"
                                                    onChange={(e) => InputChange(e)} value={formObj.email}
                                                    placeholder="Email" />
                                                {formObj?.email?.length == 0 ? " " : !isValidEmail() &&
                                                    <p className='text-danger fs-12'> Please Enter Valid Email</p>
                                                }
                                            </div>

                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="number">Number</label>
                                                <input type="text" name='number' maxLength={10} className="form-control"
                                                    onChange={(e) => InputChange(e)} value={formObj.number}
                                                    placeholder="Mobile Number" />
                                                {
                                                    formObj?.number?.length == 0 ? " " : !isValidPhoneNumber() &&
                                                        <p className='text-danger fs-12'> Invalid phone number type </p>
                                                }
                                            </div>

                                            <div className="col-md-12 mb-3  ">
                                                <label htmlFor="select">Job position</label>
                                                <select className='select-control' name="jobPosition"
                                                    onChange={(e) => InputChange(e)}
                                                    value={formObj.jobPosition} >
                                                    <option className='select-default' defaultValue={"Select"} >Select</option>
                                                    <option value={"Php  developer"}>Php  developer</option>
                                                    <option value={"React Js Developer"}>React Js Developer</option>
                                                    <option value={"Python developer"}>Python developer</option>
                                                    <option value={"Java developer"}> Java developer </option>
                                                    <option value={"BDE"}>BDE</option>
                                                    <option value={"Digital Marketing"}>Digital Marketing</option>
                                                    <option value={"Graphic designer"}>Graphic designer</option>
                                                </select>

                                                {
                                                    missingField.length > 0 &&
                                                    <p className='text-danger fs-12'> {missingField}</p>
                                                }

                                            </div>
                                            <div className="col-md-12 mb-3  ">
                                                <label className="custom-file-label" htmlFor="customFileLang">Upload resume</label>
                                                <div className="custom-file">
                                                    <input type="file"
                                                        className="custom-file-input form-control"
                                                        id="customFileLang" lang="es"
                                                        onChange={(e) => InputChange(e)}
                                                        name='uploadResume'
                                                        accept=".doc, .pdf, .jpg, .png , .jpeg"
                                                    />
                                                </div>
                                                {
                                                    missingField.length > 0 &&
                                                    <p className='text-danger fs-12'> {missingField}</p>
                                                }
                                            </div>
                                            <div className="md-form col-md-12">
                                                <label htmlFor="form7">Message</label>
                                                <textarea id="form7" className="md-textarea form-control"
                                                    name='message'
                                                    onChange={(e) => InputChange(e)}
                                                    value={formObj.message}
                                                    rows="3"
                                                    placeholder='Write message here...'
                                                ></textarea>
                                            </div>

                                            <div className='text-center'>
                                                <button type="submit"
                                                    onClick={(e) => handleForm(e)}
                                                    className="btn btn-primary btn-shadow btn-rounded w-md-25" id="send">Apply</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                </Modal>

            </div>


        </>
    )
}

export default Career