import React from 'react'
import BottomFooterLine from './BottomFooterLine'
import logo from '../Image/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import * as Scroll from 'react-scroll';

import call from '../Image/Call_Deploy intelligent automation copy 3.png'
import instgram from '../Image/Instagram.png'
import facebook from '../Image/Facebook-28.png'
import youtube from '../Image/YouTub-27.png'
import linkdin from '../Image/linkedin.png'
import gmail from '../Image/Gmail_Deploy intelligent automation copy 2.png'
import locatiomn from '../Image/Location_Deploy intelligent automation copy 4.png'


const Footer = () => {

  const scroller = Scroll.scroller;
  const navigate = useNavigate()

   const navigateAndScroll = (navigatePage) => {
    navigate(navigatePage)
    setTimeout(async () => {
      Scroll.animateScroll.scrollToTop();
    }, 1000)
  }

  const goToHomeAndScroll = async (scrollName, offsetValue) => {
    navigate('/platform')
    setTimeout(async () => {
      await scroller.scrollTo(scrollName, {
        duration: 500,
        delay: 50,
        smooth: true,
        offset: offsetValue
      });
    }, 1000)
  };
  return (
    <>
      <footer>
        <div className="site-footer my-font fontFamily">
          <div className="wrapper-1290">
            <div className="row">
              <div className="col-lg-4 col-sm-12 pr-125">
                <a onClick={() => navigateAndScroll('/')}>
                  <img
                    src={logo}
                    className="lazyload footer-logo "
                    alt="logo"
                    loading="lazy"
                  />
                </a>
                <p className='fs-14  my-font fw-500' style={{ textAlign: "justify" }}>
                  Welcome to KSVSoftTech, your premier destination for comprehensive IT solutions and hosting services. As a leading provider in the industry, we pride ourselves on delivering top-notch web hosting solutions backed by round-the-clock customer support. Experience seamless  with us and embark on a journey of  success.
                </p>



                <div className="mt-4 mb-4">
                  <Link to={'https://www.linkedin.com/company/ksvsofttech/'} target="_blank"  >
                    <img src={linkdin} width={55} className='ml-minus' />
                  </Link>
                  <Link to={'https://www.instagram.com/_ksvsofttech/'}   target="_blank"  > <img src={instgram} width={55} ></img> </Link>
                  <Link to={'https://www.facebook.com/ksvsofttech'}   target="_blank"  > <img src={facebook} width={55} /> </Link>
                  <Link to={'https://youtube.com/@KSVSoftTech?si=Aj-BQzPcQKtV7nsf'}   target="_blank" >
                    <img src={youtube} width={55} />
                  </Link>

                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 m-mt-40 m-fixed-w ">
                <h2 className="fs-18  fontFamily pb-4 pt-4 pt-md-0 fw-600 pt-12">Quick Links</h2>
                <div className="footer-unlist">
                  <ul
                    onMouseOut={(e) => { e.target.classList.remove("hover-effect") }}
                    onMouseOver={(e) => { e.target.classList.add("hover-effect") }}
                  >
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/')}>
                      Home
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/about')}>
                      About Us
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/contactpage')}>
                      Contact Us
                    </li>

                  </ul>
                </div>

                <h2 className="fs-18 fontFamily pb-4 fw-600 pt-md-3 pt-12">Solution</h2>
                <div className="footer-unlist">
                  <ul
                    onMouseOut={(e) => { e.target.classList.remove("hover-effect") }}
                    onMouseOver={(e) => { e.target.classList.add("hover-effect") }}
                  >
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/hwforces')}>
                      HWForces
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/erpAccounting')}>
                      Accounting & Finance
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/erpOrder')}>
                      Orders & Procurement
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/manufacturing')}>
                      Manufacturing
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/erpInventory')}>
                      Inventory Warehouse
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/crm')}>
                      Intitutional CRM
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => navigateAndScroll('/eWallet')}>
                      E-wallet
                    </li>
                  </ul>
                </div>

              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 m-mt-40 m-fixed-w">
                <h2 className="fs-18  fontFamily pb-4 fw-600 pt-12">Platform</h2>
                <div className="footer-unlist">
                  <ul
                    onMouseOut={(e) => { e.target.classList.remove("hover-effect") }}
                    onMouseOver={(e) => { e.target.classList.add("hover-effect") }}
                  >
                    <li className="my-font fs-14 fw-500"
                      onClick={() => goToHomeAndScroll("appDev", "50")}>
                      Application Development

                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => goToHomeAndScroll("ai", "")}>
                      AI/Machine Learning&nbsp;
                    </li>
                    <li onClick={() => goToHomeAndScroll("webDesign", "")}>
                      <a className="my-font fs-14 fw-500">
                        UI/UX WebDesign&nbsp;
                      </a>
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => goToHomeAndScroll("Digital", "")}>
                      Digital Marketing&nbsp;
                    </li>
                    <li
                      className="my-font fs-14 fw-500"
                      onClick={() => goToHomeAndScroll("mobility", "")}>
                      Mobility Solutions&nbsp;
                    </li>
                  </ul>
                </div>

              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 m-fixed-w">
                <h2 className="fs-18  fontFamily pb-4 fw-600 pt-12">Contact Us</h2>

                <div className="">
                  {/* <h2 className="footer-title pt-60">Indore</h2> */}
                  <div className="footer-unlist fontFamily">
                    <ul>

                      <li className="color-hit-grey d-flex align-items-center">
                        <img src={gmail} width={35} />
                        <p className="my-font ps-md-1 fs-14 fw-500">info@ksvsofttech.com</p>
                      </li>
                      <li className="color-hit-grey d-flex align-items-center">
                        <img src={call} width={35} />
                        <p className="my-font fs-14 ps-md-1  fw-500"> 093014 25492 </p>
                      </li>
                      <li className="color-hit-grey d-flex align-items-center">
                        <img src={locatiomn} width={35} />
                        <p className="my-font fs-14 ps-md-1  fw-500 ">
                          201, Labbik Regency, Old Palasia, Indore, Madhya Pradesh 452001
                        </p>
                      </li>
                      <li className='iframe-container  d-flex justify-content-center mt-4'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.0707803109544!2d75.8886028108396!3d22.725610427258992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd69fc801f0b%3A0x7f5839fb31bd5186!2sKSVSoftTech!5e0!3m2!1sen!2sin!4v1709118155712!5m2!1sen!2sin" width="400" height="250" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div className='d-flex justify-content-center mt-2 p-4 align-items-center border-top'>
            <p className="copyright  my-font fs-14 fw-500 text-center">
              Copyright © 2024 All Rights Reserved By KSVSoftTech
            </p>
          </div>
        </div>

      </footer>
    </>
  )
}

export default Footer