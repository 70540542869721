// Loader.js

import React from 'react';
import '../componentStyle.css'
import logo from '../../Image/logo.png'

const Loader = () => {
    return (
        <>

            <div className="loader-container">
                <div>
                    <img src={logo}
                        width={200}
                        alt="Logo"
                        className='ksv-logo'
                    />
                    <div className="loading d-block">
                        <div className="blue-line"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loader;
