import React, { useState } from 'react'
import future from '../Image/future.png'
import cutomerCentric from '../Image/customer-centric.png'
import solution from '../Image/solution.png'
import transformation from '../Image/transformation.png'
import success from '../Image/success.png'
import commitment from '../Image/commitment.png'
import innovation from '../Image/innovation.png'


import focusImg from './About/AbtImg/focusImg.jpg'
import shappingF from './About/AbtImg/fut.jpg'
import succImg from './About/AbtImg/succ.jpg'


export const OfferSection = () => {

    const [toggleTag, setToggleTag] = useState("engagement")
    return (
        <>

            <section className="offer-section  z-0 pd-60">
                {/* <p className="d-flex align-items-center justify-content-center">
                    <span className="offer-cta fs-14 fw-400 line-height-20">
                        Company Overview
                    </span>
                </p> */}
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <h2 className="my-font h-text fw-600 font-52 line-height-60">
                        <div data-aos="fade-up-right">
                            Explore our mission, vision and focus
                            {/* <span className='higlight'> &nbsp;Focus</span> */}
                        </div>
                    </h2>
                    <p className="d-block max-width-600 fs-18 fw-400 line-height-24 text-center my-font offer-detail">
                        <div data-aos="fade-up-left">
                            Discover our company's guiding principles and aspirations as we pave the way for a brighter future in the digital landscape
                        </div>
                    </p>
                </div>
                <div className="d-flex hiringPartner hiringPartnerScroll hiringPartnermobile align-items-center justify-content-center">
                    <div
                        className={`max-width-250 offer-tabs cursor-pointer  ${toggleTag == "engagement" && 'active'}`}
                        onClick={() => setToggleTag("engagement")}
                    >
                        Our Mission
                    </div>
                    <div
                        className={`max-width-250 offer-tabs cursor-pointer ${toggleTag == "location" && 'active'}`}
                        onClick={() => setToggleTag("location")}
                    >
                        Our Vision
                    </div>
                    <div
                        className={`max-width-250 offer-tabs cursor-pointer ${toggleTag == "hiring" && 'active'}`}
                        onClick={() => setToggleTag("hiring")}
                    >
                        Our Focus
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {toggleTag == "engagement" && (
                                <div
                                    data-aos="zoom-in"
                                    id="engagementCards"
                                    className="justify-content-center"
                                >

                                    <div className="offer-engagement-cards  tab-cards mar-e-38 " style={{ backgroundImage: `url(${succImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                        <div className="overlay"></div>
                                        <figure className="offer-card-icon">
                                            <img src={success} alt className='tab-logo' />
                                        </figure>
                                        <h3 className="fs-28 fontFamily fw-600 tab-h text-light line-height-28">
                                            Driving Growth and Success
                                        </h3>
                                        <h6 className="fs-16 fw-400 fontFamily text-light  line-height-24 card-content ">
                                            At KSVSoftTech, our mission is to empower businesses worldwide with innovative technology solutions that drive growth and success. We are committed to delivering exceptional software products and services that exceed client expectations, foster long-term partnerships, and contribute positively to the global IT landscape.

                                        </h6>

                                    </div>

                                </div>
                            )}
                            {toggleTag == "location" && (
                                <div
                                    id="engagementCards"
                                    data-aos="zoom-in"
                                    className="justify-content-center"
                                >

                                    <div className="offer-engagement-cards  tab-cards mar-e-38 " style={{ backgroundImage: `url(${shappingF})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                        <div className="overlay"></div>
                                        <figure className="offer-card-icon">
                                            <img src={future} alt className='tab-logo' />
                                        </figure>
                                        <h3 className="fs-28 fontFamily fw-600 tab-h text-light line-height-28">
                                            Continuous Innovation
                                        </h3>
                                        <h6 className="fs-16 fw-400 fontFamily text-light  line-height-24 card-content ">
                                            Our vision at KSVSoftTech is to create a future where technology seamlessly integrates with everyday operations to enhance efficiency, productivity, and overall business performance. We aspire to be recognized as a global leader in IT consulting and software development, renowned for our commitment to excellence, innovation, and client satisfaction.
                                        </h6>

                                    </div>
                                </div>
                            )}
                            {toggleTag == "hiring" && (
                                <div
                                    data-aos="zoom-in"
                                    id="engagementCards"
                                    className="justify-content-center"
                                >
                                    <div className="offer-engagement-cards  tab-cards mar-e-38 " style={{ backgroundImage: `url(${focusImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                        <div className="overlay"></div>


                                        <figure className="offer-card-icon">
                                            <img src={solution} alt className='tab-logo' />
                                        </figure>

                                        <h3 className="fs-28 fontFamily fw-600 tab-h text-light line-height-28">
                                            Delivering Tailored Solutions
                                        </h3>
                                        <h6 className="fs-16 fw-400 fontFamily text-light  line-height-24 card-content ">
                                            At KSVSoftTech, we deliver advanced software solutions tailored to meet each client's unique needs, prioritizing understanding their business goals to develop effective custom solutions, emphasizing quality, reliability, and innovation to keep our clients ahead in today's competitive market
                                        </h6>

                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


