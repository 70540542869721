import React from 'react'

const BottomFooterLine = () => {
    return (
        <div className="bottom-footer">
            <div className="wrapper">
                <div className="row">
                    <div className="col-12 overflow-hidden ">
                        <p className="scroll fw-600">
                        Empowering Businesses &nbsp;
                            <span className="bg-linear-gradient fw-600">with KSVSoftTech.</span>
                            <span className="color-white px-13"> | </span> Empowering businesses &nbsp;
                            <span className="bg-linear-gradient fw-600">with KSVSoftTech.</span>
                            <span className="color-white px-13"> | </span> Empowering businesses &nbsp;
                            <span className="bg-linear-gradient fw-600">with KSVSoftTech.</span>
                            <span className="color-white px-13"> | </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BottomFooterLine