import React from 'react'
import faceleft from './solutionImg/facescan.jpg'
import faceapi from './solutionImg/faceApi.jpg'
import faceActive from './solutionImg/faceActive.webp'
import enroll from './solutionImg/enroll.webp'
import identify from './solutionImg/identify.webp'
import liveVideo from './solutionImg/liveVideo.webp'
import periocular from './solutionImg/Periocular.webp'
import verify from './solutionImg/verify.webp'

import ContactUs from '../contactUs/ContactUs'
import FAQ from '../FAQ'
import BottomFooterLine from '../BottomFooterLine'

const faceFature = [
    { img: faceActive , tag: "Active Liveness Detection", tagline: "“Are you really there?”"  , content : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero minus dolorum enim voluptate ipsum?" },
    { img: liveVideo , tag: "Video Live Detection", tagline: "“Is this video recorded live?”"  , content : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero minus dolorum enim voluptate ipsum?"},
    { img: enroll, tag: "Enroll", tagline: "“How can I recognize you?”" , content : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero minus dolorum enim voluptate ipsum?" },
    { img: identify, tag: "Identify", tagline: "“Who are you?” or “Are you on the list?”" , content : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero minus dolorum enim voluptate ipsum?" },
    { img: periocular, tag: "Periocular", tagline: "“Are you who you say you are behind the mask?”",content : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero minus dolorum enim voluptate ipsum?" },
    { img: verify, tag: "Verify", tagline: "“Are you who you say you are?”" , content : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero minus dolorum enim voluptate ipsum?" },
]

const FaceREcog = () => {
    return (
        <>
            <section className='face-first section'>
                <div className='crm-overlay' />
                <div className='container erp-pd '>
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className='home-5-content row d-flex align-items-center' >
                                <div className='col-md-6'>
                                    <h1 className='home-5-title mt-md-0 mt-4'>Institutional CRM, Trusted CRM Solutions for Full Student Lifecycle Management!</h1>
                                    <p className='fs-14 mt-md-3 mt-2 txt-justify'>
                                        Our Institutional CRM software is tailored to meet the unique needs of educational institutions, providing a comprehensive solution for managing student data, academic processes, and administrative tasks
                                    </p>
                                    {/* <p className="btn ms-0 mt-md-4 mt-3 rounded  text-white py-2 px-4  theme-color">
                                        <span className="fs-16 d-inline-block fontFamily ">
                                            video
                                        </span>
                                    </p> */}
                                </div>
                                <div className='col-md-6 '>
                                    <img src={faceleft} className='faceH-img mt-md-0 mt-4 img-fluid' alt="" srcSet="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='erp-second'>
                <div className='container-lg  '>
                    <h1 className='home-5-title text-center'>Face Recognisation Web Service</h1>
                 
                    <div className='row mt-md-5 mt-3 d-flex align-items-center ' >
                        <div className='col-md-6 '>
                            <img src={faceapi} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                        <div className='col-md-6 ps-md-5 ps-0'>
                            <h6 className='home-5-title font-36 mt-md-0 mt-4'>Powerful APIs easily implemented</h6>
                            <p className='fs-20 mt-md-3 mt-1 txt-justify'>
                            Offered as an API service, the BioID Web Service can be easily integrated into any existing application infrastructure. Biometric data is kept anonymous to protect user privacy and is completely under the control of the service provider. As a cloud service, it is extremely scalable to meet any performance and storage requirements, making it available at all times and applicable for a wide range of usage scenarios
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='face-third'>
        <div className='container-lg text-center  erp-pd'>
          <h3 className='font-36'> Get the most out of Chat  </h3>
          <p>Discover why use to chat and call every day.</p>
          <div className='home-5-content row mt-md-5 mt-3  d-flex justify-content-center gap-md-5 ' >
            {
              faceFature?.map((item, index) => (
                <div key={index + "___"} className='col-md-5 text-center  crm-card'>
                  <img src={item?.img} className='face-F-img img-fluid' alt="" srcSet="" />
                  <h3 className='mt-md-3'>
                    {item?.tag}
                  </h3>
                  <h6 className='txt-grey'>{ item?.tagline}</h6>
                  <p className='fs-14 pt-md-2'>
                   {item?.content}
                  </p>
                </div>
              ))
            }
          </div>
        </div>

      </section>
      {/* <ContactUs /> */}
      <FAQ />
      <BottomFooterLine />

        </>
    )
}

export default FaceREcog