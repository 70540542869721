import React from 'react'

import './blog.css'
import blog1 from './blogimg/blog1.jpg'
import blog2 from './blogimg/blog2.jpg'
import blogger from './blogimg/blogger.png'

const BLog = () => {
    return (
        <section className='blog-sec '>

            <div className="container-lg pd-top">

                <div className="row">
                    {/* posts */}
                    <div className="col-md-8">
                        <div className="panel row d-lg-flex blog-container align-items-center">
                            <div className=" col-lg-8 panel-body my-md-4 col-md-12 ">
                                <h4>Bootstrap 3.0</h4>
                                <small className="text-muted">By <a href="#"><strong> John Doe</strong></a> |  Post on Jan 8, 2013  | 58 comments</small>
                                <p className="fs-12 pt-md-4" style={{ textAlign: "justify" }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eros nibh, viverra a dui a, gravida varius velit. Nunc vel tempor nisi. Aenean id pellentesque mi, non placerat mi. Integer luctus accumsan tellus. Vivamus quis elit sit amet nibh lacinia suscipit eu quis purus. Vivamus tristique est non ipsum dapibus lacinia sed nec metus.
                                </p>

                            </div>

                            <div className="image-wrapper col-lg-4 col-md-12 ">
                                <a className="image-wrapper text-center " href="#">
                                    <img src={blog1} className='img-fluid ' alt="Photo of Blog" />
                                    <div className="image-overlay" />
                                </a>
                            </div>
                            <div>
                                <a href="#"><i className="fa fa-angle-double-right" /> Continue reading</a>
                                <span className="post-like text-muted ">
                                    <i className="fa fa-heart" /> <span className="like-count">25</span>
                                </span>
                            </div>
                        </div>

                        <div className="panel  row d-lg-flex blog-container align-items-center">
                            <div className=" col-lg-8 panel-body my-md-4 col-md-12 ">
                                <h4>Bootstrap 3.0</h4>
                                <small className="text-muted">By <a href="#"><strong> John Doe</strong></a> |  Post on Jan 8, 2013  | 58 comments</small>
                                <p className="fs-12 pt-md-4" style={{ textAlign: "justify" }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eros nibh, viverra a dui a, gravida varius velit. Nunc vel tempor nisi. Aenean id pellentesque mi, non placerat mi. Integer luctus accumsan tellus. Vivamus quis elit sit amet nibh lacinia suscipit eu quis purus. Vivamus tristique est non ipsum dapibus lacinia sed nec metus.
                                </p>
                            </div>
                            <div className="image-wrapper col-lg-4 col-md-12 ">
                                <a className="image-wrapper text-center " href="#">
                                    <img src={blog2} className='img-fluid' alt="Photo of Blog" />
                                    <div className="image-overlay" />
                                </a>
                            </div>
                            <div>
                                <a href="#"><i className="fa fa-angle-double-right" /> Continue reading</a>
                                <span className="post-like text-muted ">
                                    <i className="fa fa-heart" /> <span className="like-count">25</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="panel d-flex align-items-center gap-lg-5 gap-1">
                            <a className="pull-left" href="#">
                                <img src={blogger} className='rounded-circle' alt="Popular Post" />
                            </a>
                            <div className="fs-14">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </div>
                        </div>
                        <div className="panel d-flex align-items-center gap-lg-5 gap-1">
                            <a className="pull-left" href="#">
                                <img src={blogger} className='rounded-circle' alt="Popular Post" />
                            </a>
                            <div className="fs-14">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </div>
                        </div>
                        <div className="panel d-flex align-items-center gap-lg-5 gap-1">
                            <a className="pull-left" href="#">
                                <img src={blogger} className='rounded-circle' alt="Popular Post" />
                            </a>
                            <div className="fs-14">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </div>
                        </div>
                        <div className="panel d-flex align-items-center gap-lg-5 gap-1">
                            <a className="pull-left" href="#">
                                <img src={blogger} className='rounded-circle' alt="Popular Post" />
                            </a>
                            <div className="fs-14">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </div>
                        </div>
                        <div className="panel d-flex align-items-center gap-lg-5 gap-1">
                            <a className="pull-left" href="#">
                                <img src={blogger} className='rounded-circle' alt="Popular Post" />
                            </a>
                            <div className="fs-14">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default BLog