import React from 'react'
import process from '../Image/process1.png'

const DevelopmentProcess = () => {
    return (
        <>
            <section className="solvesTech mt-md-3 my-font">
                <div className="wrapper-1290">

                    <div className="container">
                        <div className="row mb-8">
                            <div className="col-12 col-lg-12 d-flex flex-column align-items-center">
                            <h1 className='font-48 fw-600  mb-3'>
                                    Our working process
                                    {/* <span className='higlight' > Working</span> Process */}
                                </h1>
                                <p className="fs-16 text-md-center  line-height-24 max-width-700 fw-400 color-mid-grey my-font">
                                    Our software development team uses flexible project management methods to ensure timely delivery and adaptability
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-lg-12 pt-md-5 text-center">
                                <img src={process} className='img-fluid process-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DevelopmentProcess