import React from "react";
import "./solutionStyle.css";
import erp from "./solutionImg/Main Page.png";
import checked from "./solutionImg/checked.png";
import plannning from "./solutionImg/Planning and scheduling.png";
import accounting from "./solutionImg/finance and accounting.png";
import chain from "./solutionImg/Supply chain management.png";
import cusM from "./solutionImg/Customer and order management.png";
import prjM from "./solutionImg/Project Management.png";

import ContactUs from "../contactUs/ContactUs";
import FAQ from "../FAQ";
import BottomFooterLine from "../BottomFooterLine";

import automation from "./solutionImg/Automated Replenishment (IMG).png";
import realTimeStock from "./solutionImg/Real-Time Stock Tracking.png";
import inventoryOpt from "./solutionImg/Inventory Optimization (IMG).png";
import architecture from "./solutionImg/Cloud-Based Architecture (IMG).png";

const featureArr = [
  "Secure data storage and management.",
  "Access inventory information from anywhere.",
  "Real-time updates across all devices.",
  "Seamless integration with other business systems.",
];
const featureArr1 = [
  "Instant visibility into inventory levels.",
  "Track stock movement across locations.",
  "Monitor stockouts in real-time.",
  "Prevent overstocking and reduce waste",
];
const featureArr2 = [
  "Set up smart reorder points.",
  "Receive automated alerts for low stock.",
  "Streamline the ordering process.",
  "Ensure optimal stock levels at all times.",
];
const featureArr3 = [
  "Automated invoicing and billing.",
  "Real-time financial reporting.",
  "Real-time updates on dashboard.",
  "Seamless integration with other business systems.",
];

const ErpInventory = () => {
  return (
    <>
      <section className="erp-first">
        <div className="container">
          <div className="row  d-flex align-items-center">
            <div className="col-md-8">
              <h1 className="home-5-title font-52 mt-md-0 mt-4">
                Inventory & warehouse with ERP
              </h1>
              <p className="fs-14 mt-md-3 mt-1 txt-justify">
                At KSVSoftTech, we offer advanced Inventory & Warehouse
                management solutions designed to streamline operations, enhance
                productivity, and drive profitability for your business.
              </p>
              {/* <p href="" className="btn mt-md-2 mt-4 rounded fs-16 fw-400 text-white border mt-md-3 py-2 px-3 ctaHeader"><span className="px-1 d-inline-block fontFamily fw-500">Videos </span></p> */}
            </div>
            <div className="col-md-4 ">
              <img
                src={erp}
                className="erpH-img mt-md-0 mt-3 img-fluid"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="erp-second">
        <div className="container-lg  ">
          <div className="row  d-flex align-items-center ">
            <div className="col-md-6 order-md-1 order-2 margin-top">
              <img
                src={realTimeStock}
                className="desktop-img mt-md-0 mt-3 img-fluid"
                alt=""
                srcset=""
              />
            </div>
            <div className="col-md-6 ps-md-5 ps-0 order-md-2 order-1">
              <h1 className="home-5-title font-48 fw-600 mt-md-0 mt-4">
                Real-time stock tracking 
                {/* <span className="higlight"> Stock Tracking </span> */}
              </h1>
              <p className="fs-16 mt-md-3 mt-1 txt-justify">
                Gain visibility into your inventory levels at all times,
                allowing you to track stock movement, monitor stockouts, and
                prevent overstocking.
              </p>
              <div className=" crm-content right">
                <ul className="ps-0 mt-md-2">
                  {featureArr?.map((item, index) => (
                    <li key={index + "_F"}>
                      <div>
                        <img src={checked} className="checked-img" />
                      </div>
                      <p className="fs-16"> {item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row  margin-top d-flex align-items-center ">
            <div className="col-md-6 ps-md-5 ps-0 ">
              <h1 className="home-5-title font-48 fw-600 mt-md-0 mt-4">
                Automated replenishment
                {/* <span className="higlight"> Replenishment </span> */}
              </h1>
              <p className="fs-16 mt-md-3 mt-1 txt-justify">
                Streamline your inventory replenishment process with automated
                alerts and reorder points, ensuring that you always have the
                right amount of stock on hand to fulfill customer orders.
              </p>
              <div className=" crm-content right">
                <ul className="ps-0 mt-md-2">
                  {featureArr1?.map((item, index) => (
                    <li key={index + "_F"}>
                      <div>
                        <img src={checked} className="checked-img" />
                      </div>
                      <p className="fs-16"> {item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6 ">
              <img
                src={automation}
                className="desktop-img mt-md-0 mt-3 img-fluid"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div className="row margin-top d-flex align-items-center ">
            <div className="col-md-6 order-md-1 order-2">
              <img
                src={inventoryOpt}
                className="desktop-img mt-md-0 mt-3 img-fluid"
                alt=""
                srcset=""
              />
            </div>
            <div className="col-md-6 ps-md-5 ps-0 order-md-2  order-1">
              <h1 className="home-5-title font-48 fw-600 mt-md-0 mt-4">
                Inventory optimization
                {/* <span className="higlight"> Optimization </span> */}
              </h1>
              <p className="fs-16 mt-md-3 mt-1 txt-justify">
                Utilize advanced analytics and forecasting tools to optimize
                your inventory levels, reduce carrying costs, and maximize
                profitability.
              </p>
              <div className=" crm-content right">
                <ul className="ps-0 mt-md-2">
                  {featureArr2?.map((item, index) => (
                    <li key={index + "_F"}>
                      <div>
                        <img src={checked} className="checked-img" />
                      </div>
                      <p className="fs-16"> {item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row  margin-top d-flex align-items-center ">
            <div className="col-md-6 ps-md-5 ps-0 ">
              <h1 className="home-5-title font-48 fw-600 mt-md-0 mt-4">
              Finance and  accounting 
              {/* <span className="higlight"> Accounting </span> */}
              </h1>
              <p className="fs-16 mt-md-3 mt-1 txt-justify">
              Streamline financial operations with efficient and accurate management. Gain comprehensive insights and control over your financial data.
              </p>
              <div className=" crm-content right">
                <ul className="ps-0 mt-md-2">
                  {featureArr3?.map((item, index) => (
                    <li key={index + "_F"}>
                      <div>
                        <img src={checked} className="checked-img" />
                      </div>
                      <p className="fs-16"> {item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6 ">
              <img
                src={architecture}
                className="desktop-img mt-md-0 mt-3 img-fluid"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* <ContactUs /> */}
      <FAQ />
      <BottomFooterLine />
    </>
  );
};

export default ErpInventory;
