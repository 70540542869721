import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Carousel } from "react-bootstrap";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Outlet } from "react-router-dom";
import Loader from "./component/loader/Loader";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); 
  }, []);

  return (
    <>
      {loading ? <Loader /> : (
        <>
          <Header />

          <div>
            <div id="Mainheaderbox" />
            <main>
              <Outlet />
            </main>
          </div>

          <div id="Mainfooterbox"></div>
          <Footer />
        </>
      )}

    </>
  );
}

export default App;
