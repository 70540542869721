
export const viewJob = [
    {
        profile: "Php Developer", openings: "1", experience: "1 to 2 year", location: "Indore",
        skills: ["HTML", "CSS", "Javascript", "MySql", "MongoDB", "Apache"],
        jobDescription: [
            "Design client-side and server-side architecture",
            "Build the front-end of applications through appealing visual design",
            "Develop and manage well-functioning databases and application ",
            "Write effective APIs",
            "Test software to ensure responsiveness and efficiency",
            " Create security and data protection settings",
            "Build features and applications with a mobile responsive design",
            "Write technical documentation",
            "Good communication skills"
        ]

    },
    {
        profile: "Java Developer", openings: "3", experience: "0 to 6 month", location: "Indore",
        skills: ["HTML", "CSS", "Spring Boot" , " Hibernate" , "Spring"  ],
        jobDescription: [
            "Experience with java frameworks ( Spring, Spring Boot  , Hibernate ).",
            "Good knowledge of data base ( SQL , etc. )",
            "Strong problem- solving and analytical abilities",
            "Familiarity with version control systems ( Git.)",
            "Excellent teamwork And communication skills",
            "Good knowledge of web development ( HTML, CSS, JavaScript).",
            "Bachelor’s degree in computer science Or a related filed"
        ]
    }
]

