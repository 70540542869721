import React from 'react'
import HeroSEction from "./HeroSection.jsx";
import FourCards from './FourCards.jsx';
import TrustedBusiness from "./Technology.jsx";
import DevelopmentProcess from "./DevelopmentProcess.jsx";
import GetInTouch from "./GetInTouch.jsx";
import BottomFooterLine from "./BottomFooterLine.jsx";
import FAQ from './FAQ.jsx'
import ServiceCard from './ServiceCard.jsx';
import ParallaxAnimation from './ParallaxAnimation.jsx';
import Clients from './Clients.jsx';


import Review from './REview.jsx';
import CardOne from './HorizontalCard/CardOne.jsx';
import PeopleInfo from "./PeopleInfo.jsx";
import { OfferSection } from "./OfferSection.jsx";
import FeaturedOn from './FeaturedOn.jsx';
import FAQsectionFor from './About/FAQsectionFor.jsx'

const HomePage = () => {
    return (
        <>
            <HeroSEction />
            <FourCards />
            <ParallaxAnimation />
            <ServiceCard />
            <TrustedBusiness />
            <GetInTouch />
            <DevelopmentProcess />
            <Clients />
            <FAQ />
            <BottomFooterLine />
        </>
    )
}

export default HomePage