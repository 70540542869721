import React from 'react'
import './platform.css'

import ptWeb from './ptImages/ptWeb.png'
import dgM from './ptImages/DgM.png'
import Uiux from './Uiux'
import aiMg from './ptImages/AI-img.png'
import mobility1 from './ptImages/mobility-1.png'
import AIML from "./ptImages/AIMachine Learning.png"
import Application from "./ptImages/Application Development.png"
import FAQ from '../FAQ'
import BottomFooterLine from '../BottomFooterLine.jsx'
import Mobility from "./ptImages/Mobility Solutions.png"
import UIUX from "./ptImages/UIUX WebDesign.png"
import Digital from "./ptImages/Digital Marketing.png"

const Platform = () => {
    return (
        <>
            <section className='platfm-first d-flex align-items-center'>
                <div className=' chat-overlay' />
                <div className='container '>
                    <div className='row home-5-content' >
                        <div className='col-md-12 text-md-center mobile-pd'>
                            <h1 className='mt-md-0 mt-4 fw-600 font-52'>
                                Empowering your digital journey with innovative solutions
                            </h1>
                            <p className='fs-14 mt-md-3 mt-1 txt-justify'>
                                We deliver solutions that go beyond code through an approach that reflects craftsmanship, exploration, and care.
                            </p>

                        </div>
                    </div>
                </div>

            </section>
            <section className=''>
                <div className="container p-0">
                    <div className='erp-pd'>
                        <div className="row justify-content-center ">
                            <div className="col-12">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h1 className="title mb-3 fw-600 font-48">Our platforms
                                    {/* <span className='higlight'>   Platforms</span> */}
                                    </h1>
                                    <p className="text-muted para-desc mx-auto mb-0">
                                        With KSVSoftTech, you are guaranteed the perfect solutions for your business
                                    </p>
                                </div>
                            </div>{/*end col*/}
                        </div>{/*end row*/}
                        <div className="row d-flex justify-content-center ">
                            <div className="col-lg-4 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                                <div className="card service-wrapper rounded border-0  p-4">
                                    <div className="icon text-center text-custom h1  rounded bg-white">
                                        <img src={AIML} alt="" />
                                    </div>
                                    <div className="content mt-4">
                                        <h5 className="title">AI/Machine learning</h5>
                                        <p className="text-muted mt-3 mb-0">
                                        Intelligent technologies are the future
                                        </p>

                                    </div>

                                </div>
                            </div>{/*end col*/}
                            <div className="col-lg-4 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                                <div className="card service-wrapper rounded border-0  p-4">
                                    <div className="icon text-center text-custom h1  rounded bg-white">
                                        <span className="uim-svg" style={{}}>
                                            <img src={Application} alt="" />
                                        </span>
                                    </div>
                                    <div className="content mt-4">
                                        <h5 className="title">Application development</h5>
                                        <p className="text-muted mt-3 mb-0">
                                            Unleash the power of the web
                                        </p>

                                    </div>
                                    <div className="big-icon h1 text-custom">
                                        <span className="uim-svg" style={{}}>
                                        </span>
                                    </div>
                                </div>
                            </div>{/*end col*/}
                            <div className="col-lg-4 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                                <div className="card service-wrapper rounded border-0  p-4">
                                    <div className="icon text-center text-custom h1  rounded bg-white">

                                        <span className="uim-svg" style={{}}>
                                            <img src={UIUX} alt="" />
                                        </span>
                                    </div>
                                    <div className="content mt-4">
                                        <h5 className="title">UI/UX webdesign
                                        </h5>
                                        <p className="text-muted mt-3 mb-0">
                                            Experience design that captivates

                                        </p>

                                    </div>
                                    <div className="big-icon h1 text-custom">
                                        <span className="uim-svg" style={{}}>


                                        </span>
                                    </div>
                                </div>
                            </div>{/*end col*/}

                            <div className="col-lg-4 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                                <div className="card service-wrapper rounded border-0  p-4">
                                    <div className="icon text-center text-custom h1  rounded bg-white">
                                        <span className="uim-svg" style={{}}>
                                            <img src={Digital} alt="" />
                                        </span>
                                    </div>
                                    <div className="content mt-4">
                                        <h5 className="title">Digital marketing</h5>
                                        <p className="text-muted mt-3 mb-0">
                                            Ignite your brand's digital footprint
                                        </p>

                                    </div>
                                    <div className="big-icon h1 text-custom">
                                        <span className="uim-svg" style={{}}>

                                        </span>
                                    </div>
                                </div>
                            </div>{/*end col*/}


                            <div className="col-lg-4 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                                <div className="card service-wrapper rounded border-0  p-4">
                                    <div className="icon text-center text-custom h1  rounded bg-white">
                                        <span className="uim-svg" style={{}}>
                                            <img src={Mobility} alt="" />
                                        </span>
                                    </div>
                                    <div className="content mt-4">
                                        <h5 className="title">Mobility solutions</h5>
                                        <p className="text-muted mt-3 mb-0">
                                        Innovative mobile solutions
                                        </p>
                                    </div>
                                    <div className="big-icon h1 text-custom">
                                        <span className="uim-svg" style={{}}> </span> </div>
                                </div>
                            </div>{/*end col*/}

                        </div>{/*end row*/}
                    </div>
                </div>

            </section>
            <section className='ai-block section' name="ai" id="ai">
                <div className='ai-overlay' />
                <div className='container erp-pd '>
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className='home-5-content row d-flex align-items-center mobile-pd' >
                                <div className='col-lg-6 col-md-12 pe-md-2 order-md-1 order-2 '>
                                    <img src={aiMg} className='ai-img mt-md-0 mt-4 img-fluid' alt="" srcSet="" />
                                </div>
                                <div className='col-lg-6 col-md-12 order-md-2 order-1 '>
                                    <h1 className='home-5-title mt-md-0 mt-4 fw-600 font-48'>AI & machine learning
                                     {/* <span className='higlight'>Machine Learning</span> */}
                                    </h1>
                                    <p className='fs-14 mt-md-3 mt-2 txt-justify'>
                                        Harness the power of AI and ML to drive innovation and gain a competitive edge. Our team of data scientists and engineers leverage advanced algorithms and machine learning techniques to unlock valuable insights and optimize processes, propelling your business into the future.
                                    </p>
                                    <h5 className='home-5-title  mt-md-4 mt-4'> Services</h5>
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-6 mt-4 pt-2">
                                                <div className="media d-flex align-items-center rounded  p-3">
                                                    <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                                    <h6 className="ml-3 mb-0"><a className="text-dark">
                                                        AI consulting
                                                    </a></h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mt-4 pt-2">
                                                <div className="media d-flex align-items-center rounded  p-3">
                                                    <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                                    <h6 className="ml-3 mb-0"><a className="text-dark">Machine learning development</a></h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mt-4 pt-2">
                                                <div className="media d-flex align-items-center rounded  p-3">
                                                    <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                                    <h6 className="ml-3 mb-0"><a className="text-dark">AI chatbots and virtual assistants
                                                    </a></h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mt-4 pt-2">
                                                <div className="media d-flex align-items-center rounded  p-3">
                                                    <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                                    <h6 className="ml-3 mb-0"><a className="text-dark">AI-driven automation</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section name="appDev" id="appDev" className='' style={{ backgroundColor: "" }}>
                <div className='container-lg erp-pd'>
                    {/* <h1 className='home-5-title '>What is ERP software and what does it do for business?</h1> */}
                    <div className='row mobile-pd' >
                        <div className='col-md-12 col-lg-6  ps-0'>
                            <h1 className='home-5-title mt-md-0 mt-4 fw-600 font-48'>
                                Application development
                                {/* <span className='higlight'>Development</span> */}
                            </h1>
                            <p className='fs-14 mt-md-3 mt-1 txt-justify'>
                                We craft captivating websites that blend seamless functionality with stunning aesthetics. From concept to launch, our expert web developers ensure your online presence resonates with your target audience, driving engagement and conversions.
                            </p>
                            <h5 className='home-5-title  mt-md-4 mt-4'> Services</h5>
                            <div className="row">
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Web application development</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Cloud application development</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">API development</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2 ">
                                    <div className="media d-flex  rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 px-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Application maintenance and support</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <img src={ptWeb} className='pt-web mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                    </div>

                </div>
            </section>

            <Uiux />

            <section name="Digital" id="Digital">
                <div className='container-lg erp-pd'>
                    <div className='row mt-md-5 mt-3 d-flex align-items-center mobile-pd' >

                        <div className='col-md-12 col-lg-6 '>
                            <h1 className='home-5-title mt-md-0 mt-4 fw-600 font-48'>Digital marketing
                            {/* <span className='higlight'>Marketing</span> */}
                            </h1>
                            <p className='fs-14 mt-md-3 mt-1 txt-justify'>
                                Leverage the full potential of digital channels with our data-driven marketing strategies. Our digital gurus craft personalized campaigns that amplify your brand's reach, cultivate customer loyalty, and propel your business to new heights.

                            </p>
                            <h5 className='home-5-title  mt-md-4 mt-4'>Services 
                             </h5>
                           
                            <div className="row">
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Social media marketing</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Content marketing</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Search engine optimization</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Graphic designing</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 col-lg-6 ps-md-4 ps-0'>
                            <img src={dgM} className='dgm-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='mobility-conatiner' name="mobility" id='mobility' >
                <div className='container-lg '>
                    <div className='row mt-md-5 mt-3 p-lg-2 d-flex align-items-center ' >
                        <div className='col-lg-6 col-md-12 text-center  grid-container order-lg-1 order-2 '>
                            <img src={mobility1} className='mobility-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />

                        </div>
                        <div className='col-lg-6 col-md-12 ps-md-4 ps-0 order-lg-2 order-1'>
                            <h1 className='home-5-title mt-md-0 fw-600 font-48'>Mobility solution
                            {/* <span className='higlight'>Solution</span> */}
                            </h1>
                            <p className='fs-14 mt-md-3 mt-1 txt-justify'>
                                Empower your business with cutting-edge mobile solutions that streamline operations and enhance customer engagement. Our mobile experts craft robust, scalable applications tailored to your unique requirements, ensuring unparalleled efficiency and convenience.
                            </p>
                            <h5 className='home-5-title  mt-md-4 mt-4'>Services </h5>
                            
                            <div className="row">
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Mobile app development</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex align-items-center rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 p-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Mobile UI/UX design</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex  rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 px-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Cross platform app development</a></h6>
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media d-flex  rounded  p-3">
                                        <i className="fa-duotone fa-right h4 mb-0 text-custom me-2 px-md-2 "></i>
                                        <h6 className="ml-3 mb-0"><a className="text-dark">Mobile app maintenance and support</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FAQ />
            <BottomFooterLine />
        </>
    )
}

export default Platform


