import React from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import "./solutionStyle.css";

const industries = [
  {
    id: "1",
    title: "Finance Companies",
    description:
      "Ideal for startups and established companies looking to offer digital banking solutions.",
    bgClass: "bg-finance",
  },
  {
    id: '2',
    title: "Retail Industry",
    description:
      "Enhance in-store and online shopping experiences with integrated payment solutions.",
    bgClass: "bg-retail",
  },
  {
    id: '3',
    title: "E-commerce",
    description:
      "Streamline checkout processes and increase conversion rates.",
    bgClass: "bg-ecommerce",
  },
  {
    id: '4',
    title: "Travel and Hospitality",
    description:
      "Offer seamless booking and payment experiences for customers.",
    bgClass: "bg-travel",
  },
  {
    id: '5',
    title: "Entertainment and Event Management",
    description:
      "Simplify ticketing and in-venue purchases for concerts, sports events, and festivals.",
    bgClass: "bg-entertainment",
  },
  {
    id: '6',
    title: "Educational Institutions",
    description:
      "Simplify fee payments and campus transactions.",
    bgClass: "bg-education",
  },
  {
    id: '7',
    title: "Healthcare Industry",
    description:
      "Streamline patient payments and insurance claims.",
    bgClass: "bg-healthcare",
  },
  {
    id: '8',
    title: "Real Estate",
    description:
      "Manage rent collections, maintenance fees, and property transaction deposits efficiently.",
    bgClass: "bg-real-estate",
  },
];

const SliderSection = () => {
  return (
    <div className="container">
      <h1 className="text-center mb-md-5 mb-4 mt-10 mt-md-5">
        Industries that can  benefit
        {/* <span className="higlight fw-700 "> Benefit</span> */}
      </h1>
      <p className="text-center fs-14 mb-md-5 mb-4">
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout.
      </p>
      <Splide
        options={{
          perPage: 4,
          gap: '10px',
          // pagination: true,
          // autoplay: true,
          interval: 3000,
          breakpoints: {
            768: {
              perPage: 1,
              gap: '0.5rem',
            }
          }
        }}
        aria-label="Industries Slider"
      >
        {industries.map((industry) => (
          <SplideSlide key={industry.id} className="p-2">
            <div className={`card slider-card slider-card-hg ${industry.bgClass}`}>
              <div className="card-body">
                <h5 className="slider-title mb-2">{industry.title}</h5>
                <p className="slider-font">{industry.description}</p>
              </div>
              <div className="card-bg-image" />
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default SliderSection;
