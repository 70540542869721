import React from 'react'
import './solutionStyle.css'
import manufacture from './solutionImg/manufacturing and production.png'
import plannning from './solutionImg/Planning and scheduling.png'
import accounting from './solutionImg/finance and accounting.png'
import chain from './solutionImg/Supply chain management.png'
import cusM from './solutionImg/Customer and order management.png'
import prjM from './solutionImg/Project Management.png'

import ContactUs from '../contactUs/ContactUs'  
import FAQ from '../FAQ'
import BottomFooterLine from '../BottomFooterLine'
import checked from './solutionImg/checked.png'

import productDevelopment from './solutionImg/Product Development.png'
import softwareEngineering from './solutionImg/Software Engineering_1.png'
import QualityAssurance from './solutionImg/Quality Assurancee_1.png'
import ManufacturingImg from './solutionImg/Hardware Manufacturing_1.png'
import supplyChain from './solutionImg/Supply Chain and Logistics_1.png'
import continuousImprovment from './solutionImg/Continuous Improvemen_1.png'
import certification from './solutionImg/Certifications and Compliance_1.png'

const erpFeature = [
    { feature: "Finance and accounting", img: accounting ,subPoints:"Streamline financial operations and reporting."},
    { feature: "Supply chain management", img: chain ,subPoints:"Optimize inventory and supplier relationships."},
    { feature: "Planning and scheduling ", img: plannning ,subPoints:" Efficient resource allocation and task planning."},
    { feature: "Project management", img: prjM ,subPoints:"Track progress and manage project resources."},
    { feature: "Customer and order management", img: cusM ,subPoints:" Improve customer relationships and order processing."},

]


const featureArr = [
    "Ideation: Collaborate with customers and experts to spark innovation.",
    "Design: Expert team uses advanced methods to create optimal product designs.",
    "Prototyping: Build and test functional models to validate and refine designs.",
    "Testing: Rigorous evaluation ensures products meet highest quality standards."
]
const featureArr2 = [
    "Agreement to coding standards and guidelines.",
    "Accurate code reviews and testing.",
    "Utilization of version control systems (e.g., Git) and continuous integration/deployment pipelines.",
    "Secure coding practices and regular security audits."
]
const featureArr3 = [
    "Integration testing.",    
    "Performance testing.",
    "Usability testing.",
    "Compatibility testing.",
]
const featureArr4 = [
    "Advanced manufacturing facilities.",
    "End-to-end production process",
    "Strict quality control and compliance",
    "Optimized supply chain through partnerships",
]

const featureArr5 = [
    "Efficient, responsive, and cost-effective processes.",
    "Just-in-time manufacturing and lean production.",
    "Minimized waste, maximized customer value.",
    "Streamlined inventory, warehousing, and distribution through advanced tech.",
]

const featureArr6 = [
    "Committed to improving processes, products, and services.",
    "Continuous improvement methodologies applied.",
    "Drive incremental improvements and innovation.",
    "Incorporate customer feedback and market trends into product roadmaps.",
]

const featureArr7 = [
    "ISO 9001 (Quality Management).",
    "ISO 27001 (Information Security).",
    "HIPAA (Health Insurance Portability and Accountability Act).",
    "PCI-DSS (Payment Card Industry Data Security Standard).",
]

const Manufacturing = () => {
    return (
        <>
          <section className='erp-first'>
                <div className='container '>
                    <div className='row d-flex align-items-center ' >
                        <div className='col-md-8'>
                            <h1 className='home-5-title font-52 mt-md-0 mt-4'>
                            Manufacturing & production
                            </h1>
                            <p className='fs-14 mt-md-3 mt-1 txt-justify'>
                            At KSVSoftTech, we follow an accurate and constant product development process to ensure we deliver innovative, high-quality solutions. Our approach involves several key phases.
                            </p>
                            {/* <p href="" className="btn mt-md-2 mt-4 rounded fs-16 fw-400 text-white border mt-md-3 py-2 px-3 ctaHeader">
                                <span className="px-1 d-inline-block fontFamily fw-600">Videos </span>
                            </p> */}
                        </div>
                        <div className='col-md-4 '>
                            <img src={manufacture} className='erpH-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='erp-second'>
                <div className='container-lg'>
                    {/* <h1 className='home-5-title '>What is Accounting & Finance with ERP and what does it do for business?</h1> */}
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 order-md-1 order-2 '>
                            <img src={productDevelopment} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                        <div className='col-md-6 ps-md-5 ps-0 order-md-2 order-1'>
                            <h1 className='home-5-title font-48 fw-600 mt-md-0 mt-4'>
                            Product development
                            {/* <span className="higlight">  Development </span> */}
                            </h1>
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                            Our development process is guided by agile methodologies, primarily Scrum and Kanban, enabling us to be flexible, responsive, and customer-focused.
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 ps-md-5 ps-0 '>
                            <h1 className='home-5-title font-48 fw-600 mt-md-0 mt-4'>
                            Software engineering
                            {/* <span className="higlight">  Engineering </span> */}
                            </h1>
                           <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                            Our software engineering practices prioritize quality, scalability, and security. We follow industry best practices, including:
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr2?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex justify-content-center'>
                            <img src={softwareEngineering} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 order-md-1 order-2  '>
                            <img src={QualityAssurance} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                        <div className='col-md-6 ps-md-5 ps-0 order-md-2 order-1'>
                            <h1 className='home-5-title font-48 fw-600 mt-md-0 mt-4'>
                            Quality assurancee
                            {/* <span className='higlight' >  Assurancee </span>  */}
                            </h1>
                           <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                            Quality is at the core of everything we do. Our quality assurance processes encompass a range of testing methodologies:
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr3?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 ps-md-5 ps-0 '>
                            <h1 className='home-5-title font-48 fw-600 mt-md-0 mt-4'>
                            Hardware manufacturing 
                             {/* <span className='higlight' > Manufacturing   </span>  */}
                            </h1>
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                            Our state-of-the-art facilities ensure a seamless production process from start to finish, maintaining the highest standards of quality and efficiency.
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr4?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex justify-content-center'>
                            <img src={ManufacturingImg} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 order-md-1 order-2 '>
                            <img src={supplyChain} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                        <div className='col-md-6 ps-md-5 ps-0 order-md-2 order-1'>
                            <h1 className='home-5-title font-48 fw-600 mt-md-0 mt-4'>
                            Supply chain and logistics
                            {/* <span className='higlight' >  Logistics </span> */}
                            </h1>
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                            Our streamlined processes are designed to be efficient, responsive, and cost-effective, utilizing just-in-time manufacturing and lean production principles.
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr5?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                             <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 ps-md-5 ps-0 '>
                            <h1 className='home-5-title font-48 fw-600 mt-md-0 mt-4'>
                            Continuous  improvement
                             {/* <span className='higlight' >  Improvement </span> */}
                            </h1>   
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                            Committed to constant enhancement, we drive innovation by applying improvement methodologies and incorporating customer feedback.
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr6?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex justify-content-center'>
                            <img src={continuousImprovment} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 order-md-1 order-2 '>
                            <img src={certification} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcset="" />
                        </div>
                        <div className='col-md-6 ps-md-5 ps-0 order-md-2 order-1'>
                            <h1 className='home-5-title font-48 fw-600 mt-md-0 mt-4'>
                            Certifications and  compliance
                            {/* <span className='higlight'>  Compliance  </span>  */}
                            </h1>
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                            Certified to various industry standards, we ensure quality, security, and regulatory compliance, giving our customers confidence in our products.
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr7?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='erp-third'>
                <div className='container'>
                    <h1 className='text-center font-48' >ERP Features
                    {/* <span className="higlight">  Features</span> */}
                    </h1>
                    <div className='row d-flex justify-content-center mt-md-5 mt-3'>
                        {
                            erpFeature?.map((item, index) => (
                                <div key={`${item?.feature + "-" + index}`} className="col-lg-4 col-md-6 ">
                                    <div className="card-feature " >
                                        <div className="card-body text-center">
                                            <img src={item?.img} className='hw-logo' alt="" />
                                            <h6 className="card-subtitle mt-md-3 mb-md-1 ">{item?.feature}</h6>
                                            <p className="card-text fs-12 text-muted">{item?.subPoints}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            {/* <ContactUs /> */}
            <FAQ />
            <BottomFooterLine />
        </>
    )
}


export default Manufacturing