import React from "react";
import "./solutionStyle.css";
import Scalability from "./solutionImg/Group 152.png"
import Customization from "./solutionImg/Group.png"
import Ready from "./solutionImg/Group 154.png"
import Enhanced from "./solutionImg/Group 4.png"
import Experience from "./solutionImg/Group 153.png"
import Costs from "./solutionImg/Group (1).png"
import ewallet from "./solutionImg/Single Mobile Mockup.png";
import ContactUs from "../contactUs/ContactUs";
import FAQ from "../FAQ";
import feature1 from "./solutionImg/feature1.png"
import feature2 from "./solutionImg/feature2.png"
import feature3 from "./solutionImg/feature3.png"
import feature4 from "./solutionImg/feature4.png"
import feature5 from "./solutionImg/feature5.png"
import feature6 from "./solutionImg/feature6.png"
import BottomFooterLine from "../BottomFooterLine";
import erp from "./solutionImg/3 Mobile Mockup.png";
import solution from "./solutionImg/IMG With Contant.png";
import SliderSolution from "./SliderSolution";

const services = [
  {
    feature: "Enhanced security",
    img: Enhanced,
    points:
      "Protect your customers financial data with our multi-layered security approach.",
  },
  {
    feature: "Scalability",
    img: Scalability,
    points:
      "Easily handle growing user bases and increasing transaction volumes.",
  },
  {
    feature: "Customization ",
    img: Customization,
    points:
      "Tailor the E-wallet to your brand and specific business requirements. ",
  },
  {
    feature: "Improved user experience",
    img: Experience,
    points: "Offer a seamless, user-friendly interface across all devices.",
  },
  {
    feature: "Reduced operational costs",
    img: Costs,
    points:
      "Streamline payment processes and reduce dependency on traditional banking systems.",
  },
  {
    feature: "Compliance ready",
    img: Ready,
    points:
      "Built with regulatory compliance in mind, adaptable to various international standards.",
  },
];
const keyFeatures1 = [
  {
    feature: "Add Funds to Wallet",
    img: feature1,
    points:
      "Instantly top up your wallet from various sources. Keep your digital cash ready for any transaction.",
  },
  {
    feature: "Withdraw Funds from Wallet",
    img: feature2,
    points:
      "Access your money anytime. Transfer funds from your wallet to your bank account with ease.",
  },
  {
    feature: "Borderless Transfers ",
    img: feature3,
    points:
      "Send money across the globe without boundaries. Fast, secure, and cost-effective international transfers.",
  },
];
const keyFeatures2 = [
  {
    feature: "Bank Payments",
    img: feature4,
    points:
      "Direct integration with your bank accounts. Make payments and transfers without leaving the app.",
  },
  {
    feature: "Popular Payments",
    img: feature5,
    points:
      "Pay bills, recharge mobile, or shop online. Access frequently used services with just a tap.",
  },
  {
    feature: "Wallet Statements ",
    img: feature6,
    points:
      "Track your spending effortlessly. Detailed transaction history and downloadable statements for easy financial management.",
  },
];

const Ewallet = () => {
  return (
    <>
      <section className="erp-first">
        <div className="container">
          <div className="row  d-flex align-items-center">
            <div className="col-md-8">
              <h1 className="home-5-title font-40 mt-md-0 mt-4">
                Bring change to your business with our customized E-wallet
                solution
              </h1>
              <p className="fs-14 mt-md-3 mt-1 txt-justify">
                Welcome to the future of digital transactions! Our customized
                E-wallet solution, built on modern spring boot microservices
                architecture, offers a secure, scalable, and user-friendly
                platform for businesses across various industries. 
                {/* Whether You're in fintech, retail, or any sector requiring seamless
                digital payments, our e-wallet solution is designed to meet your
                unique needs. */}
              </p>
              {/* <p href="" className="btn mt-md-2 mt-4 rounded fs-16 fw-400 text-white border mt-md-3 py-2 px-3 ctaHeader"><span className="px-1 d-inline-block fontFamily fw-500">Videos </span></p> */}
            </div>
            <div className="col-md-4">
              <img
                src={erp}
                className="wallet-img mt-md-0 mt-3 img-fluid"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container ew-second">
          <div className="row container d-flex mx-auto align-items-center justify-content-lg-center">
            <div className="col-12 text-center">
              <h1 className="font-weight-normal home-5-title font-48">
                Key Feature
                {/* <span className="higlight fw-700 "> Feature</span>  */}
              </h1>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
              <div className="col-12 col-md-4 mt-3 mt-md-3">
                {keyFeatures1?.map((item, index) => (
                  <div key={`${item?.feature}-${index}`} className="mb-4">
                    <div className="card-feature-wallet">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <img src={item?.img} className="wallet-logo" alt="" />
                          <h6 className="card-subtitle mb-md-1 ml-2">
                            {item?.feature}
                          </h6>
                        </div>
                        <p className="card-text fs-12 text-muted">
                          {item?.points}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <img
                src={ewallet}
                className="ewallet-img col-12 col-md-4 mt-4 mt-md-4 "
                alt=""
              />
              <div className="col-12 col-md-4 mt-3 mt-md-3">
                {keyFeatures2?.map((item, index) => (
                  <div key={`${item?.feature}-${index}`} className="mb-4">
                    <div className="card-feature-wallet">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-end ">
                          <h6 className="card-subtitle mb-md-1 mr-2">
                            {item?.feature}
                          </h6>
                          <img src={item?.img} className="wallet-logo1" alt="" />
                        </div>
                        <p className="d-flex card-text fs-12 text-muted justify-content-end">
                          {item?.points}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <SliderSolution />
      <section className="erp-third">
        <div className="container">
          <h1 className="text-center mb-md-5 mb-4">
            Benefits of  customized E-wallet
            {/* <span className="higlight fw-700 "> Customized E-wallet</span> */}
          </h1>
          <div className="row d-flex justify-content-center">
          {services.map((service) => (
                <div key={service.id} className="col-lg-4 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                    <div className="card service-wrapper rounded border-0 p-4">
                        <div className="icon-1 text-center text-custom h1 rounded bg-white wallet-icon">
                        <img src={service?.img} className="wallet-image" alt="" />
                        </div>
                        <div className="content mt-4">
                            <h5 className="title">{service.feature}</h5>
                            <p className="services mt-3 mb-0">
                                {service.points}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </div>
      </section>
      <section className="row container d-flex mx-auto align-items-center justify-content-lg-center">
        <div className="col-md-6 d-flex justify-content-center align-items-center flex-wrap">
          <img
            src={solution}
            className="solution-img col-6 mt-md-0 mt-3 img-fluid"
            alt=""
            srcset=""
          />
        </div>
        <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-start">
          <h1 className="text-wallet mb-md-5 mb-4">Why choose our E-wallet solution?
          {/* <span className="higlight fw-700 "> E-wallet Solution?</span> */}
          </h1>
          <p className="mb-4">
            Transform your business today with our customized E-wallet solution.
            Contact us for a demo and discover how we can tailor our platform to
            your unique needs!
          </p>
        </div>
      </section>
      {/* <ContactUs /> */}
      <FAQ />
      <BottomFooterLine />
    </>
  );
};

export default Ewallet;
