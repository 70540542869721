import React from 'react'
import mobileSS1 from './solutionImg/mobiless-1.png'
import mobileSS2 from './solutionImg/mobiless-2.png'
import grpChat from './solutionImg/grp-chat.svg'

import chatOne from './solutionImg/chat--.jpg'
import videoCall from './solutionImg/videoCll.jpg'
import clling from './solutionImg/calling.avif'
import grpChat2 from './solutionImg/grpchat.avif'
import ContactUs from '../contactUs/ContactUs'
import FAQ from '../FAQ'
import BottomFooterLine from '../BottomFooterLine'
import chatDeshB from './solutionImg/mobDeskTab.jpeg'

const chatFeature = [
  { img: chatOne, tag: "Say Anything", tagline: "" },
  { img: clling, tag: "Speak Freely", tagline: "" },
  { img: videoCall, tag: "Video Connectivity", tagline: "" },
  { img: grpChat2, tag: "Get Together with Groups", tagline: "" },
]

const Chat = () => {
  return (
    <>
      <section className='chat-first'>
        <div className='chat-overlay' />
        <div className='container '>
          <div className='row  d-flex align-items-center home-5-content' >
            <div className='col-md-6'>
              <h1 className='home-5-title mt-md-0 mt-4'>Speak Freely!!</h1>
              <p className='fs-14 mt-md-3 mt-1 txt-justify'>
                Say "hello" to a different messaging experience. An unexpected focus on privacy, combined with all of the features you expect.
              </p>
              <p href="" className="btn mt-md-2 mt-4 rounded fs-16 fw-400 text-white border mt-md-3 py-2 px-3 ctaHeader"><span className="px-1 d-inline-block fontFamily fw-500">Videos </span></p>
            </div>
            <div className='col-md-6 column text-md-start text-center ps-md-5 position-relative chat-screenshot '>
              <img src={mobileSS1} className='ss1 mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
              <img src={mobileSS2} className='ss2 mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
            </div>
          </div>
        </div>

      </section>
      <section className='erp-second'>
        <div className='container-lg  '>
          <div className='text-center'>
            <h1 className='home-5-title '>Why use KSVSoftTEch Chat?</h1>
            <p>Explore below to see why Signal is a simple,<br />  powerful, and secure messenger</p>
          </div>
          <div className='row mt-md-5 mt-3 d-flex ' >
            <div className='col-md-6 '>
              <img src={grpChat} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
            </div>
            <div className='col-md-6 ps-md-5 ps-0  chat-card d-flex align-items-center '>
              <div className=''>
                <h6 className='home-5-title text-center font-36 mt-md-0 mt-4'>
                  Create an invite-only place where you belong</h6>
                <p className='fs-20 mt-md-3 mt-1 txt-justify'>
                  Chat servers are organized into topic-based channels where you can collaborate, share, and just talk about your day without clogging up a group chat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container erp-pd'>
          <h1 className='text-center ' >
            Multiplatform. Always Synced.
          </h1>
          <p className='text-center fs-18 mt-md-2 mb-md-5 mb-4 px-md-5'>
            Mobile, tablet and desktop - your chats on Viber are 100% synced between any device you use.
          </p>
          <div className='text-center'>
            <img src={chatDeshB} className='crmDeshboard img-fluid mt-md-5' alt="" srcSet="" />
          </div>

        </div>
      </section>
      <section className='chat-third'>
        <div className='container-lg text-center  erp-pd'>
          <h3 className='font-36'> Get the most out of Chat  </h3>
          <p>Discover why use to chat and call every day.</p>
          <div className='home-5-content row mt-md-5 mt-3  d-flex justify-content-center gap-md-5 ' >

            {
              chatFeature?.map((item, index) => (
                <div key={index + "___"} className='col-md-5 text-center  crm-card'>
                  <img src={item?.img} className='chat-F-img img-fluid' alt="" srcSet="" />
                  <h3 className='mt-md-3'>
                    {item?.tag}
                  </h3>
                  <p className='fs-14 pt-md-2'>
                    Share text, voice messages, photos, videos, GIFs and files for free. Signal uses your phone's data connection so you can avoid SMS and MMS fees.
                  </p>
                </div>

              ))
            }
          </div>
        </div>

      </section>

      {/* <ContactUs /> */}
      <FAQ />
      <BottomFooterLine />
    </>
  )
}

export default Chat