import React from 'react'
import './solutionStyle.css'
import erp from './solutionImg/Accounting & Finance.png'

import plannning from './solutionImg/Planning and scheduling.png'
import accounting from './solutionImg/finance and accounting.png'
import chain from './solutionImg/Supply chain management.png'
import cusM from './solutionImg/Customer and order management.png'
import prjM from './solutionImg/Project Management.png'

import ContactUs from '../contactUs/ContactUs'
import FAQ from '../FAQ'
import BottomFooterLine from '../BottomFooterLine'
import checked from './solutionImg/checked.png'


import finacialManagment from './solutionImg/Financial Management.jpg'
import Buget from './solutionImg/Budgeting, Planning and Forecasting.jpg'
import payCycle from './solutionImg/Procure-to-Pay Cycle.jpg'
import robustA from './solutionImg/Robust Analytics and Reporting.jpg'


const erpFeature = [
    { feature: "Finance and accounting", img: accounting ,subPoint:"Streamline financial operations and reporting."},
    { feature: "Supply chain management", img: chain ,subPoint:" Optimize inventory and supplier relationships."},
    { feature: "Planning and scheduling ", img: plannning , plannning ,subPoint:"Efficient resource allocation and task planning."},
    { feature: "Project management", img: prjM ,subPoint:" Track progress and manage project resources."},
    { feature: "Customer and order management", img: cusM ,subPoint:"Improve customer relationships and order processing"},

]

const featureArr = [
    "General ledger, accounts payable, accounts receivable",
    "Fixed asset accounting and depreciation tracking",
    "Cost and profit center accounting",
    "Advanced revenue recognition and allocations",
]
const featureArr2 = [
    "Integrated financial planning and modeling",
    "Workforce and capital expenditure planning",
    "Cash flow projections and treasury management",
    "Rolling forecasts and baseline comparisons",
    // "Robust data integration provides a holistic view to drive strategic financial planning."
]
const featureArr3 = [
    "Spend analysis and eProcurement    ",
    "Purchase order and requisition approval workflows",
    "Taxation and regulatory compliance",
    "Invoice matching and payment processing",
    "This closed-loop process maximizes cost savings and transparency."
]
const featureArr4 = [
    "Deep insights into financial and operational metrics",
    "Audit trails and internal controls",
    "Automated tax calculations and filings",
    "Integration with BI and CPM tools    ",
]

const ErpAccounting = () => {
    return (
        <>
            <section className='erp-first'>
                <div className='container '>
                    <div className='row d-flex align-items-center ' >
                        <div className='col-md-8'>
                            <h1 className='home-5-title font-52 mt-md-0 mt-4'>
                                Accounting & finance with ERP
                            </h1>
                            <p className='fs-14 mt-md-3 mt-1 txt-justify'>
                                At KSVSoftTech, we understand the critical role an integrated Enterprise Resource Planning (ERP) system plays in streamlining accounting and finance operations. Our industry-leading ERP solutions provide a centralized platform to manage all core financial processes with efficiency and control.

                            </p>
                            {/* <p href="" className="btn mt-md-2 mt-4 rounded fs-16 fw-400 text-white border mt-md-3 py-2 px-3 ctaHeader">
                                <span className="px-1 d-inline-block fontFamily fw-600">Videos </span>
                            </p> */}
                        </div>
                        <div className='col-md-4 '>
                            <img src={erp} className='erpH-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                    </div>
                </div>

            </section>
            <section className='erp-second'>
                <div className='container-lg  '>
                    
                    <div className='row mt-3 d-flex align-items-center ' >
                  

                        <div className='col-md-6  margin-top order-md-1 order-2'>
                            <img src={finacialManagment} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                        <div className='col-md-6 ps-lg-5 ps-md-2 ps-0 order-md-2 order-1'>
                           <h1 className='home-5-title font-48  fw-600 mt-md-0 mt-4'>
                                Financial management
                                {/* <span className='higlight'>Management </span> */}
                            </h1>
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                                Our ERP software seamlessly handles all aspects of financial management including:
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 ps-lg-5 ps-0 o'>
                           <h1 className='home-5-title font-48  fw-600 mt-md-0 mt-4'>
                                Budgeting, planning and  forecasting
                                {/* <span className='higlight'>  Forecasting </span> */}
                            </h1>
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                                The budgeting and planning modules of our ERP solution simplify complex forecasting and "what-if" scenario analysis with features like:
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr2?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6 '>
                            <img src={Buget} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 order-md-1 order-2 '>
                            <img src={payCycle} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                        <div className='col-md-6 ps-lg-5 ps-md-2 ps-0  order-md-2 order-1'>
                           <h1 className='home-5-title font-48  fw-600 mt-md-0 mt-4'>
                                Procure to  pay cycle
                                {/* <span className='higlight'>  Pay Cycle </span> */}
                            </h1>
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                                KSVSoftTech's ERP centrally manages the complete procure-to-pay cycle from sourcing to vendor management:
                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr3?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row margin-top d-flex align-items-center ' >
                        <div className='col-md-6 ps-lg-5 ps-0 '>
                           <h1 className='home-5-title font-48 fw-600 mt-md-0 mt-4'>
                                Robust analytics and  reporting
                                {/* <span className='higlight'>    Reporting </span> */}
                            </h1>
                            <p className='fs-16 mt-md-3 mt-1 txt-justify'>
                                Our ERP features powerful financial analytics with customizable dashboards, data visualization, reporting, and compliance capabilities that provide:

                            </p>
                            <div className=' crm-content right'>
                                <ul className='ps-0 mt-md-2'>
                                    {
                                        featureArr4?.map((item, index) => (
                                            <li key={index + "_F"}>
                                                <div>
                                                    <img src={checked} className='checked-img' />
                                                </div>
                                                <p className='fs-16'> {item}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6 '>
                            <img src={robustA} className='desktop-img mt-md-0 mt-3 img-fluid' alt="" srcSet="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='erp-third'>
                <div className='container'>
                    <h1 className='text-center fw-600 font-48 ' >ERP Features
                    {/* <span className='higlight'>    Features</span> */}
                    </h1>
                    <div className='row d-flex justify-content-center margin-top'>
                        {
                            erpFeature?.map((item, index) => (
                                <div key={`${item?.feature + "-" + index}`} className="col-lg-4 col-md-6 ">
                                    <div className="card-feature " >
                                        <div className="card-body text-center">
                                            <img src={item?.img} className='hw-logo' alt="" />
                                            <h6 className="card-subtitle mt-md-3 mb-md-1 ">{item?.feature}</h6>
                                            <p className="card-text fs-12 text-muted">{item?.subPoint}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            {/* <ContactUs /> */}
            <FAQ />
            <BottomFooterLine />
        </>
    )
}

export default ErpAccounting   