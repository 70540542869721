import React from "react";
import experience from "../Image/experience.png";
import Customization from "../Image/Customization.png";
import innovation from "../Image/innovation.png";
import CustomerSupport from "../Image/CustomerSupport.png";

const FourCards = () => {
  return (
    <>
      <section className="tech-container">
        <div className="container ">
          <div className="erp-pd">
            <div className="row justify-content-center ">
              <h1 className="font-48 text-md-center fw-600">
                Our core values driving IT excellence
                {/* <span className="higlight"> IT excellence </span> */}
              </h1>
            </div>
            {/*end row*/}
            <div className="row mt-md-5 mt-1 d-flex justify-content-center ">
              <div className="col-lg-3 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                <div className="card service-wrapper rounded border-0 shadow p-4">
                  <div className="icon text-center text-custom h1 shadow rounded bg-white">
                    <span className="uim-svg" style={{}}>
                      <img src={experience} className="" />
                    </span>
                  </div>
                  <div className="content mt-4">
                    <h5 className="title">Experience</h5>
                    <p className="text-muted mt-3 mb-0 my-font">
                      Over a decade of expertise in software development
                    </p>
                  </div>
                </div>
              </div>
              {/*end col*/}

              <div className="col-lg-3 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                <div className="card service-wrapper rounded border-0 shadow p-4">
                  <div className="icon text-center text-custom h1 shadow rounded bg-white">
                    <span className="uim-svg" style={{}}>
                      <img src={Customization} className="" />
                    </span>
                  </div>
                  <div className="content mt-4">
                    <h5 className="title"> Customization</h5>
                    <p className="text-muted mt-3 mb-0 my-font">
                      Tailored solutions to fit your unique business needs
                    </p>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-3 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                <div className="card service-wrapper rounded border-0 shadow p-4">
                  <div className="icon text-center text-custom h1 shadow rounded bg-white">
                    <span className="uim-svg" style={{}}>
                      <img src={innovation} className="" />
                    </span>
                  </div>
                  <div className="content mt-4">
                    <h5 className="title">Innovation</h5>
                    <p className="text-muted mt-3 mb-0 my-font">
                      Always evolving to meet industry standards
                    </p>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-3 col-md-6 col-12 mt-md-4 mt-2 pt-2">
                <div className="card service-wrapper rounded border-0 shadow p-4">
                  <div className="icon text-center text-custom h1 shadow rounded bg-white">
                    <span className="uim-svg" style={{}}>
                      <img src={CustomerSupport} className="" />
                    </span>
                  </div>
                  <div className="content mt-4">
                    <h5 className="title"> Customer support</h5>
                    <p className="text-muted mt-3 mb-0 my-font">
                      Trusted by leading industries across India
                    </p>
                  </div>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
        </div>
      </section>
    </>
  );
};

export default FourCards;
