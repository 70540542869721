import React, { useState } from 'react'
import appDev from '../Image/appDev.png'
import ai from '../Image/Aiml.png'
import service from '../Image/service.png'
import { Link, useNavigate } from 'react-router-dom'
import * as Scroll from 'react-scroll';

const ServiceCard = () => {
    const navigate = useNavigate()
    const scroller = Scroll.scroller;
    const [hoverIndex, setHoverIndex] = useState(null)

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const renderImage = () => {
        switch (hoverIndex) {
            case '1':
                return appDev;
            case '2':
                return ai;

            default:
                return service;
        }
    };

    const navigateAndScroll = (navigatePage) => {
        navigate(navigatePage)
        setTimeout(async () => {
            Scroll.animateScroll.scrollToTop();
        }, 1000)
    }

    const goToHomeAndScroll = async (scrollName, offsetValue) => {
        navigate('/platform')
        setTimeout(async () => {
            await scroller.scrollTo(scrollName, {
                duration: 500,
                delay: 50,
                smooth: true,
                offset: offsetValue
            });
        }, 1000)
    };

    return (
        <section className="mainFAQ  pd-bottom">
            <div className="">
                <div className="d-flex  viewPoint bg-services">
                    <div className="col-md-12 col-sm-12 mainFAQ-left">

                        <div className="mainFAQ-box service-box"  >

                            <div className='container'>

                                <div className="row text-light" >
                                    <div className="col-12" onMouseEnter={() => handleMouseEnter("1")} onMouseLeave={() => setHoverIndex(null)}>
                                        <div className=" service-card">
                                            <div className="card-body-div ">
                                                <div className='service-title-div'>
                                                    <h1>
                                                        <strong className="font-52 fw-600 fontFamily" >
                                                            Platforms
                                                        </strong>
                                                    </h1>
                                                    <p className=' fs-25 fw-200 pt-4 txt-justify'>
                                                        Unlocking your vision, one solution at a time.
                                                        In today's digital landscape, the need for engaging and purposeful content is more vital than ever.
                                                    </p>
                                                </div>
                                                <div className='service-content-div'>
                                                    <ul >
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => goToHomeAndScroll("appDev", "50")}  >
                                                                Application Development
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => goToHomeAndScroll("Digital", "")}>
                                                                Digital Marketing
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => goToHomeAndScroll("webDesign", "")}>
                                                                UI/UX WebDesign
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => goToHomeAndScroll("ai", "")}>
                                                                AI/Machine Learning
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => goToHomeAndScroll("mobility", "")}>
                                                                Mobility Solutions
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12" onMouseEnter={() => handleMouseEnter("2")} onMouseLeave={() => setHoverIndex(null)}>
                                        <div className=" service-card" style={{ backgroundColor: "" }}>
                                            <div className="card-body-div">
                                                <div className='service-title-div'>
                                                    <h1>
                                                        <strong className="font-52 fw-600 fontFamily " >
                                                            Solution
                                                        </strong>
                                                    </h1>

                                                    <p className=' fs-25 fw-200 pt-4 txt-justify'>
                                                        Transforming your ideas into actionable solutions.
                                                        In a world bringing with possibilities, we're here to turn your concepts into tangible results
                                                    </p>
                                                </div>
                                                <div className='service-content-div'>
                                                    <ul>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => navigateAndScroll('/hwforces')}>
                                                                HRMS
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => navigateAndScroll('/erpAccounting')}>
                                                                Accounting & Finance
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => navigateAndScroll('/erpOrder')}>
                                                                Orders & Procurement
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => navigateAndScroll('/erpInventory')}>
                                                                Inventory & Warehouse
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => navigateAndScroll('/crm')}>
                                                                CRM & Marketing Automation
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => navigateAndScroll('/manufacturing')}>
                                                                Manufacturing & Production
                                                            </div>
                                                        </li>
                                                        <li className='d-flex align-items-center hover-nav'>
                                                            <i className="fa-solid fa-arrow-right me-3 pt-1 "></i>
                                                            <div onClick={() => navigateAndScroll('/eWallet')}>
                                                                E-wallet
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 mainFAQ-right text-center">
                        <div className="FAQ-head">

                            <img
                                // src={hoverIndex == '1' ? img1 : hoverIndex == '2' ? img2 : hoverIndex == '3' ? img3 :  first}
                                src={renderImage()}
                                className={`service-img `}
                                alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceCard