import React from 'react'
import duddle from '../Image/duddle.png'
import { Link } from 'react-router-dom'

const GetInTouch = () => {
    return (
        <div className='get-in-touch'>
            <div className="wrapper-1290" style={{ zIndex: 99, position: "relative" }}>
                <div className="row job-platforms">
                    <div className=" col-lg-8 col-sm-12 d-flex align-items-center">
                        <div className="">
                            <h3 className="fs-42 fw-600 color-white mb-2">Ready to build a software solution?</h3>
                            <p className="color-white fs-20 line-height-26 fw-500 ">
                                We hire the top 1% of IT talent to deliver you innovative and secure technology solutions.
                            </p>

                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <img src={duddle} className='duddle' />
                        <br></br>
                     
                        <Link to={'/contactPage'} className="schedule_header header_btnschedule  mousehoveranimationwhite text-decoration-none my-font" >

                            Get in Touch
                        </Link>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default GetInTouch