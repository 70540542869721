// import React, { useState } from 'react'
// import './contactstyle.css'
// import axios from 'axios';
// import ReCAPTCHA from "react-google-recaptcha";
// import call from '../../Image/Call_Deploy intelligent automation copy 3.png'
// import gmail from '../../Image/Gmail_Deploy intelligent automation copy 2.png'
// import locatiomn from '../../Image/Location_Deploy intelligent automation copy 4.png'

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const formInitialValue = {
//     name: '',
//     email: '',
//     number: '',
//     message: ''
// }

// const ContactPage = () => {
//     const [formObj, setFormObj] = useState(formInitialValue);
//     const [missingField, setMissingFiled] = useState('')

//     const isValidEmail = () => {
//         const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
//         return validRegex.test(formObj.email);
//     }

//     const isValidPhoneNumber = () => {
//         const phonePattern = /^\d{10}$/;
//         return phonePattern.test(formObj.number);
//     };

//     const InputChange = (e) => {
//         setFormObj({ ...formObj, [e.target.name]: e.target.value })
//     }

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         let validEmail = isValidEmail();
//         if (!formObj.name || !formObj.email || !formObj.phone || !formObj.message) {
//             setMissingFiled('Please fill this fields.')
//             return
//         }
//         if (!validEmail) {
//             return
//         }
//         const formData = new FormData();
//         for (let key in formObj) {
//             formData.append(key, formObj[key]);
//         }
//         const headers = {
//             'Content-Type': 'multipart/form-data',
//         };
//         let res = await axios.post('', formData, {
//             headers: headers
//         })
//         if (res?.status === 200) {
//             toast.success('Thank you for your request. Your request sent successfully.')
//         } else {
//             toast.error('Something went wrong. Please try again later.')
//         }
//         setFormObj({ ...formInitialValue })
//         setMissingFiled('')
//     }

//     const handleForm = (e) => {
//         e.preventDefault();
//         toast.error('Something went wrong. Please try again later.')
//     }

//     function onChange(value) {
//         console.log("Captcha value:", value);
//     }

//     return (
//         <>
//             <ToastContainer position='top-center' reverseOrder={false} />
//             <section>
//                 <div className="row text-center ">
//                     <div className="col-sm-12">
//                         <div className="contact-map">
//                             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.0707803109544!2d75.8886028108396!3d22.725610427258992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd69fc801f0b%3A0x7f5839fb31bd5186!2sKSVSoftTech!5e0!3m2!1sen!2sin!4v1709118155712!5m2!1sen!2sin" style={{ width: '100%', height: 460, border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

//                         </div>
//                     </div>{/* end col */}
//                 </div>
//                 <div className='contact-sec2'>

//                     <div className="container erp-pd">

//                         {/* end row */}
//                         <div className="row">
//                             <h1 className='text-center text-white'>Get In Touch</h1>
//                             <p className='text-center text-white'>Let us help your business to move forward.</p>
//                         </div>
//                         <div className='row pd-60'>
//                             <div className="col-md-8 col-sm-12">
//                                 <div className="card rounded border-0 shadow p-4">

//                                     <form className='p-md-5 p-2'
//                                     //  onSubmit={(e)=>handleSubmit(e)}
//                                     >
//                                         <div className="row">
//                                             <h3 className='text-center p-4'> Contact Us</h3>
//                                             <div className="col-12 mb-3">
//                                                 <label htmlFor="name"> Name</label>
//                                                 <input type="text" name='name' maxLength={15}
//                                                     className="form-control"
//                                                     onChange={(e) => InputChange(e)} value={formObj.name}
//                                                     placeholder="Enter your name" />
//                                                 {
//                                                     formObj?.name?.length == 0 ? " " : formObj?.name?.length == 15 &&
//                                                         <p className='text-danger fs-12'> Name reached at max character </p>
//                                                 }
//                                             </div>

//                                             <div className="col-md-6 mb-3">
//                                                 <label htmlFor="email">Email</label>
//                                                 <input type="email" name='email' className="form-control"
//                                                     onChange={(e) => InputChange(e)} value={formObj.email}
//                                                     placeholder="Email"
//                                                 />
//                                                 {formObj?.email?.length == 0 ? " " : !isValidEmail() &&
//                                                     <p className='text-danger fs-12'> Please Enter Valid Email</p>
//                                                 }
//                                             </div>

//                                             <div className="col-md-6 mb-3">
//                                                 <label htmlFor="number">Number</label>
//                                                 <input type="text" name='number' maxLength={10} className="form-control"
//                                                     onChange={(e) => InputChange(e)} value={formObj.number}
//                                                     placeholder="Mobile Number" />
//                                                 {
//                                                     formObj?.number?.length == 0 ? " " : !isValidPhoneNumber() &&
//                                                         <p className='text-danger fs-12 '> Invalid phone number type </p>
//                                                 }
//                                             </div>

//                                             <div className="md-form">
//                                                 <label htmlFor="form7">Message</label>
//                                                 <textarea id="form7" className="md-textarea form-control" rows="3"
//                                                     name="message" required
//                                                     placeholder='Type your message here..'
//                                                     onChange={(e) => InputChange(e)}
//                                                 />
//                                                 {
//                                                     missingField.length > 0 &&
//                                                     <p className='text-danger fs-12'> {missingField}</p>
//                                                 }
//                                             </div>
//                                             <div className='col-md-6 col-sm-12 mt-1'>
//                                                 <ReCAPTCHA
//                                                     sitekey="6LcpQxknAAAAAK3ghNJxnfjJNH5n7kMUDIB9hVjO"
//                                                     onChange={() => onChange()}
//                                                     size='10'
//                                                 />
//                                             </div>
//                                               <div className='col-md-6 col-sm-12 text-end'>
//                                                 <button type="submit" className="btn btn-primary btn-shadow btn-rounded" id="send"
//                                                     onClick={(e) => handleForm(e)}
//                                                 >Submit</button>
//                                             </div>
//                                         </div>
//                                     </form>

//                                 </div>
//                             </div>
//                             <div className="col-md-4 col-sm-12 gap-md-2">
//                                 <div className='row text-center justify-content-center '>

//                                     <div className="col-md-12 mb-md-1">
//                                         <div className="card service-wrapper rounded border-0 shadow p-4">
//                                             <div className="contact-detail-box">
//                                                 <img src={gmail} style={{ width: 30 }} />
//                                                 <h4>Email</h4>
//                                                 <a href="" className="text-muted">info@ksvsofttech.com</a>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-12 mb-md-1 ">
//                                         <div className="card service-wrapper rounded border-0 shadow p-4">
//                                             <div className="contact-detail-box">
//                                                 <img src={call} style={{ width: 30 }} />
//                                                 <h4>Give us a call.</h4>
//                                                 <p className="text-muted">093014 25492</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-12 mb-md-1">
//                                         <div className="card service-wrapper rounded border-0 shadow p-4">
//                                             <div className="contact-detail-box">
//                                                 <img src={locatiomn} style={{ width: 30 }} />
//                                                 <h4>Our Location</h4>
//                                                 <address>
//                                                     201, Labbik Regency, Old Palasia, Indore, Madhya Pradesh 452001
//                                                 </address>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//             </section>
//         </>
//     )
// }

// export default ContactPage

import React, { useState } from "react";
import "./contactstyle.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import call from "../../Image/Call_Deploy intelligent automation copy 3.png";
import gmail from "../../Image/Gmail_Deploy intelligent automation copy 2.png";
import locatiomn from "../../Image/Location_Deploy intelligent automation copy 4.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const formInitialValue = {
  name: "",
  email: "",
  number: "",
  message: "",
};

const ContactPage = () => {
  const [formObj, setFormObj] = useState(formInitialValue);
  const [missingField, setMissingFiled] = useState("");

  const isValidEmail = () => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return validRegex.test(formObj.email);
  };

  const isValidPhoneNumber = () => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(formObj.number);
  };

  const InputChange = (e) => {
    setFormObj({ ...formObj, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validEmail = isValidEmail();
    if (!formObj.name || !formObj.email || !formObj.phone || !formObj.message) {
      setMissingFiled("Please fill this fields.");
      return;
    }
    if (!validEmail) {
      return;
    }
    const formData = new FormData();
    for (let key in formObj) {
      formData.append(key, formObj[key]);
    }
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    let res = await axios.post("", formData, {
      headers: headers,
    });
    if (res?.status === 200) {
      toast.success(
        "Thank you for your request. Your request sent successfully."
      );
    } else {
      toast.error("Something went wrong. Please try again later.");
    }
    setFormObj({ ...formInitialValue });
    setMissingFiled("");
  };

  const handleForm = (e) => {
    e.preventDefault();
    toast.error("Something went wrong. Please try again later.");
  };

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  return (
    <>
      <ToastContainer position="top-center" reverseOrder={false} />
      <section>
        <div className="row text-center ">
          <div className="col-sm-12">
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.0707803109544!2d75.8886028108396!3d22.725610427258992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd69fc801f0b%3A0x7f5839fb31bd5186!2sKSVSoftTech!5e0!3m2!1sen!2sin!4v1709118155712!5m2!1sen!2sin"
                style={{ width: "100%", height: 460, border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          {/* end col */}
        </div>
        <div className="contact-sec2">
          <div className="container erp-pd">
            {/* end row */}
            <div className="row">
              <h1 className="text-center text-white">Get In Touch</h1>
              <p className="text-center text-white">
                Let us help your business to move forward.
              </p>
            </div>
            <div className="row pd-60">
              <div className="col-md-12 col-sm-12 gap-md-2">
                <div className="row text-center justify-content-center ">
                  <div className="col-md-4 mb-md-1">
                    <div className="card rounded border-0 shadow p-4 height-1 ">
                      <div className="contact-detail-box">
                        <img src={gmail} style={{ width: 30 }} />
                        <h5 className="mb-2">For Enquiry </h5>
                        <a href="" className="text-muted">
                          info@ksvsofttech.com
                        </a>
                        <br/>
                        <h5 className="mb-2">For Sales </h5>
                        <a href="" className="text-muted">
                          sales@ksvsofttech.com
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-1 ">
                    <div className="card rounded border-0 shadow p-4 height-1 ">
                      <div className="contact-detail-box">
                        <img src={call} style={{ width: 30 }} />
                        <h5 className="mb-2">For Enquiry </h5>
                        <p className="text-muted text-center">+919301425492</p>
                        <h5 className="mb-2">For Sales </h5>
                        <a href="" className="text-muted">
                          +919201502461
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-1">
                    <div className="card rounded border-0 shadow p-4 height-1">
                      <div className="contact-detail-box">
                        <img src={locatiomn} style={{ width: 30 }} />
                        <h4 className="mb-2">Our Location</h4>
                        <address>
                          201, Labbik Regency, Old Palasia, Indore, Madhya
                          Pradesh 452001
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPage;
