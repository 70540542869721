import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider , createBrowserRouter } from 'react-router-dom';
import HomePage from './component/HomePage';
import About from './component/About/About';
import OurTeam from './component/Team/OurTeam';
import BLog from './component/Blog/Blog.jsx';
import Career from './component/career/Career.jsx';
import Hwforces from './component/solution/Hwforces.jsx';

import IntitutionalCRM from './component/solution/IntitutionalCRM.jsx';
import Chat from './component/solution/Chat.jsx';
import FaceREcog from './component/solution/FaceREcog.jsx';
import Platform from './component/Platforms/Platform.jsx';
import ContactPage from './component/contactUs/ContactPage.jsx';
import ErpOrders from './component/solution/ErpOrders.jsx';
import ErpInventory from './component/solution/ErpInventory.jsx';
import ErpAccounting from './component/solution/ErpAccounting.jsx';
import Manufacturing from './component/solution/Manufacturing.jsx';
import Ewallet from './component/solution/Ewallet.jsx';

// Initialize AOS
AOS.init();

const appRouter =  createBrowserRouter([
     {
      path : "/",
      element : <App />,
      errorElement : <> Error  Page not found</>,
      children : [
        {
          path : "/",
          element: <HomePage />
        },
        {
          path : "/about",
          element :<About />
        },
        {
          path : "/team",
          element :<OurTeam />
        },
        {
          path : "/blog",
          element :<BLog />
        },
        {
          path : "/career",
          element :<Career />
        },
        {
          path : "/hwforces",
          element :<Hwforces />
        },
        {
          path : "/erpOrder",
          element :<ErpOrders />
        },
        {
          path : "/erpInventory",
          element :<ErpInventory />
        },
        {
          path : "/erpAccounting",
          element :<ErpAccounting />
        },
        {
          path : "/crm",
          element :<IntitutionalCRM />
        },
        {
          path : "/chat",
          element :<Chat />
        },
        {
          path : "/face",
          element :<FaceREcog />
        },
        {
          path : "/platform",
          element :<Platform />
        },
        {
          path : "/contactpage",
          element :<ContactPage />
        },
        {
          path : "/manufacturing",
          element :<Manufacturing />
        },
        {
          path : "/eWallet",
          element :<Ewallet />
        },
      ]

     }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider  router={appRouter} />
  </React.StrictMode>
);

reportWebVitals();
